import { queryClient } from "@clients/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
import { DEFAULT_THEME, ThemeProvider } from "@zendeskgarden/react-theming";
import { Router } from "./Router";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

dayjs.extend(relativeTime);
dayjs.extend(utc);

import "@zendeskgarden/css-bedrock/dist/index.css";

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={DEFAULT_THEME}>
                <Router />
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
