import { ZafAppLocation } from "@localtypes/zafTypes";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { z } from "zod";
import { useZafClient } from "./useZafClient";

export const useCustomer = (location: ZafAppLocation) => {
    const zafClient = useZafClient();

    // The prefix for the User object 'get' query varies based on location of the app
    //   https://developer.zendesk.com/api-reference/apps/apps-support-api/all_locations/#user-object
    const mapLocationUserPrefix: Record<ZafAppLocation, string> = {
        ticket_editor: "ticket.requester",
        ticket_sidebar: "ticket.requester",
        user_sidebar: "user",
        top_bar: "currentUser",
    };
    const prefixUserQuery = mapLocationUserPrefix[location];

    const queryAbeCustomerId = `${prefixUserQuery}.customField:abe_customer_id`;
    const queryCustomerName = `${prefixUserQuery}.name`;
    const querySalesforceContactId = `${prefixUserQuery}.externalId`;
    const queryBrandId = "ticket.brand.id";

    const customerSchema = z
        .object({
            [queryCustomerName]: z.string(),
            [querySalesforceContactId]: z.string().nullish(),
            [queryAbeCustomerId]: z.string().nullish().optional(),
            [queryBrandId]: z.number().int().optional(),
        })
        .transform((val) => {
            return {
                customerName: val[queryCustomerName]?.toString(),
                salesforceContactId: val[querySalesforceContactId]?.toString(),
                abeCustomerId: val[queryAbeCustomerId]?.toString(),
                brandId: val[queryBrandId]?.toString(),
            };
        });

    const { isLoading, refetch, data, error } = useQuery({
        queryKey: ["customer"],
        queryFn: async () => {
            const queries = [
                queryAbeCustomerId,
                queryCustomerName,
                querySalesforceContactId,
            ];
            if (location === "ticket_editor" || location === "ticket_sidebar") {
                queries.push(queryBrandId);
            }
            const response: Record<string, string> | undefined =
                await zafClient?.get(queries);

            return customerSchema.parse(response);
        },
        enabled: !!zafClient,
        cacheTime: Infinity,
        staleTime: Infinity,
    });
    useEffect(() => {
        // Listen for changes to the ticket requester
        if (location === "ticket_sidebar" || location === "ticket_editor") {
            zafClient?.on("ticket.requester.id.changed", () => refetch());
        }
    }, [zafClient, location, refetch]);

    return {
        customerName: data?.customerName,
        salesforceContactId: data?.salesforceContactId,
        loading: isLoading,
        abeCustomerId: data?.abeCustomerId,
        ticketBrandId: data?.brandId,
    };
};
