import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

Sentry.init({
    enabled: import.meta.env.VITE_STAGE === "prod",

    dsn: import.meta.env.VITE_SENTRY_DSN,

    // don't enable replays since our Sentry plan does not currently support this
    // only capture replays on errors, disable sampling of entire sessions
    //
    // replaysSessionSampleRate: 0,
    // replaysOnErrorSampleRate: 1.0,
    // integrations: [new Sentry.Replay()],
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
