import { ZafClient } from "@localtypes/zafTypes";
import { z } from "zod";

export const ticketsSearchResponseSchema = z.object({
    tickets: z.array(
        z.object({
            url: z.string(),
            id: z.number(),
            requester_id: z.number(),
        }),
    ),
});
type TicketSearchResult = z.infer<
    typeof ticketsSearchResponseSchema.shape.tickets.element
>;

export const getTicketsQuery = async (
    ticketIds: number[],
    zafClient: ZafClient,
): Promise<TicketSearchResult[]> => {
    if (ticketIds.length === 0) return [];

    const response = await zafClient.request({
        url: `/api/v2/tickets/show_many.json`,
        type: "GET",
        contentType: "application/json",
        data: {
            ids: ticketIds.map((id) => String(id)).join(","),
        },
    });
    const ticketSearchParse = ticketsSearchResponseSchema.parse(response);
    const tickets = ticketSearchParse.tickets;
    return tickets;
};
