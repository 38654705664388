import {
    ZendeskAppAirportTransferFlightInformation,
    ZendeskAppChronology,
    ZendeskAppDepartureRoom,
    ZendeskAppGroupTrip,
    ZendeskAppHealthNotes,
    ZendeskAppTrip,
} from "@localtypes/zendesk-app.types";
import {
    BookingTrip,
    TourExtensionLineItem,
    TripTimingStatus,
} from "@localtypes/generated-gql";

export class ZendeskAppTripMapper {
    static map(trip: null | BookingTrip): ZendeskAppTrip {
        return {
            id: trip?._id ?? "",
            customerType: trip?.customerType ?? "",
            extensionSelected: !!getTourExtension(trip),
            flightsRequested: mapFlightsRequested(trip),
            hasCompletedHealthNotes:
                !!trip?.healthNotesChecklistItem?.isCompleted,
            hasCompletedOnTourContact:
                !!trip?.emergencyContactChecklistItem?.isCompleted,
            hasCompletedTsaInformation:
                !!trip?.tsaInformation?.maskedIdentityDocumentNumber &&
                !!trip.tsaInformation.issueDate &&
                !!trip.tsaInformation.expirationDate &&
                !!trip.tsaInformation.countryOfIssue &&
                !!trip.tsaInformation.firstName &&
                !!trip.tsaInformation.lastName &&
                !!trip.tsaInformation.dateOfBirth &&
                !!trip.tsaInformation.gender,
            hasCompletedPassportInformation:
                !!trip?.tsaInformation?.maskedIdentityDocumentNumber &&
                !!trip.tsaInformation.issueDate &&
                !!trip.tsaInformation.expirationDate &&
                !!trip.tsaInformation.countryOfIssue,
            hasCompletedTravelerDetails:
                !!trip?.tsaInformation?.firstName &&
                !!trip.tsaInformation.lastName &&
                !!trip.tsaInformation.dateOfBirth &&
                !!trip.tsaInformation.gender,
            hasEmergencyContacts: !!trip?.hasEmergencyContacts,
            preStay: trip?.openPreStayLineItem
                ? {
                      id: trip.openPreStayLineItem?._id,
                      startDate: trip.openPreStayLineItem?.departureDate,
                      endDate: trip.openPreStayLineItem?.endDate,
                      cityName: trip.openPreStayLineItem?.cityGateway?.name,
                  }
                : null,
            postStay: trip?.openPostStayLineItem
                ? {
                      id: trip.openPostStayLineItem?._id,
                      startDate: trip.openPostStayLineItem?.departureDate,
                      endDate: trip.openPostStayLineItem?.endDate,
                      cityName: trip.openPostStayLineItem?.cityGateway?.name,
                  }
                : null,
            hasAutoPay: false,
            isLandOnly: !!trip?.isLandOnly,
            roommateCustomers:
                trip?.roommates?.map((roommate) => ({
                    id: roommate?._id,
                    firstName: roommate.firstName ?? "",
                    lastName: roommate.lastName ?? "",
                })) ?? null,
            departure: {
                id: trip?.departure?._id ?? "",
                departureDate: trip?.departure?.departureDate ?? null,
                endDate: trip?.departure?.endDate ?? null,
                status: trip?.departure?.status ?? "",
                tourCode: trip?.departure?.tourCode ?? "",
                tourFinalizationDate: trip?.departure?.finalPaymentDeadline,
                atlasProdTourId: trip?.departure?.prodTourId,
                atlasSalesTourId: trip?.departure?.salesTourId,
                extensionDeparture: mapExtensionDeparture(trip),
                tourContent: {
                    accommodationLevel: {
                        title: trip?.departure?.tourContent?.accommodations.find(
                            (accomodation) => accomodation.name,
                        )?.name,
                    },
                },
                name: trip?.departure?.tourContent?.name ?? "",
            },
            tsaInformation: trip?.tsaInformation ?? null,
            customer: {
                id: trip?.customer?._id ?? "",
                lastName: trip?.customer?.lastName ?? "",
                firstName: trip?.customer?.firstName ?? "",
            },
            order: {
                id: trip?.booking?._id ?? "",
                balance: trip?.booking?.balance.value,
            },
            chronology: mapTimingStatusToChronology(trip?.timingStatus),
            status: trip?.status ?? "",
            termsAndConditions: trip?.termsAcceptedAt
                ? {
                      id: "",
                      acceptedDate: trip?.termsAcceptedAt ?? "",
                  }
                : null,
            departureRoom: mapDepartureRoom(trip),
            requestedRoommateNames: trip?.requestedRoommates,
            roomReservationId: trip?.openRoomLineItem?.roommate?.roommateId,
            airportTransferFlightInformation:
                mapAirportTransferFlightInformation(trip),
            groupTrip: mapGroupTrip(trip),
            healthNotes: mapHealthNotes(trip),
        };
    }
}

const mapAirportTransferFlightInformation = (
    trip: null | BookingTrip,
): ZendeskAppAirportTransferFlightInformation[] => {
    return (
        trip?.flights?.departingFlights?.map(
            (flight): ZendeskAppAirportTransferFlightInformation => ({
                departureDate: flight.departsAtLocalTime,
                departureTime: flight.departsAtLocalTime,
                arrivalDate: flight.arrivesAtLocalTime,
                arrivalTime: flight.arrivesAtLocalTime,
                gatewayFrom: flight.departureGatewayCode,
                gatewayTo: flight.arrivalGatewayCode,
                flightNumber: flight.flightNumber,
                pnr: flight.pnrLocator,
                airline: flight.carrier?.name,
            }),
        ) ?? []
    );
};

const mapDepartureRoom = (
    trip: null | BookingTrip,
): null | ZendeskAppDepartureRoom => {
    const departureRoom = trip?.departure?.rooms.find(
        (room) => room.lineItemCode === trip?.openRoomLineItem?.lineItemCode,
    );

    if (!departureRoom) {
        return null;
    }

    return {
        id: departureRoom?._id,
        bedConfiguration: departureRoom?.bed?.code,
        cabinCode: departureRoom?.cabinCode,
        roomName: departureRoom?.bed?.name,
    };
};

const mapGroupTrip = (trip: null | BookingTrip): null | ZendeskAppGroupTrip => {
    const gcDeparture = trip?.booking?.gcDeparture;
    const groupCoordinator = gcDeparture?.groupCoordinator;

    return gcDeparture
        ? {
              id: gcDeparture._id,
              groupLeaderCustomer: groupCoordinator
                  ? {
                        id: groupCoordinator._id,
                        firstName: groupCoordinator.firstName,
                        lastName: groupCoordinator.lastName,
                    }
                  : null,
          }
        : null;
};

const mapHealthNotes = (trip: null | BookingTrip): ZendeskAppHealthNotes => {
    return {
        dietaryRestrictions: trip?.travelerNotes?.dietaryNotes?.text ?? null,
        mobility: trip?.travelerNotes?.mobilityNotes?.text ?? null,
        medicalConditions: trip?.travelerNotes?.medicalNotes?.text ?? null,
    };
};

const mapFlightsRequested = (trip: null | BookingTrip) => {
    const departingFlight = trip?.openDepartingFlightLineItem;
    const returningFlight = trip?.openReturningFlightLineItem;

    return {
        departureFlight: {
            destinationCode: departingFlight?.arrivalGatewayCode,
            originCode: departingFlight?.departureGatewayCode,
            startDate: departingFlight?.departureDate,
        },
        returnFlight: {
            destinationCode: returningFlight?.arrivalGatewayCode,
            originCode: returningFlight?.departureGatewayCode,
            startDate: returningFlight?.departureDate,
        },
    };
};

const mapTimingStatusToChronology = (
    timingStatus?: TripTimingStatus | null,
): ZendeskAppChronology | null => {
    switch (timingStatus) {
        case TripTimingStatus.OnTour:
            return ZendeskAppChronology.Present;
        case TripTimingStatus.PostTour:
            return ZendeskAppChronology.Past;
        case TripTimingStatus.PreTour:
            return ZendeskAppChronology.Future;
        default:
            return null;
    }
};

const mapExtensionDeparture = (trip: null | BookingTrip) => {
    const tourExtension = getTourExtension(trip);

    const extensionName = tourExtension?._id
        ? trip?.departure?.activeExtension?.tourExtension?.internalName
        : null;

    return {
        id: tourExtension?._id ?? "",
        endDate: tourExtension?.endDate ?? null,
        startDate: tourExtension?.departureDate ?? null,
        name: extensionName ?? "-",
    };
};

const getTourExtension = (
    trip: null | BookingTrip,
): null | TourExtensionLineItem => {
    if (trip?.isOpen) {
        return trip?.openExtensionLineItem ?? null;
    }

    const anyExtensionLineItems =
        trip?.lineItems?.filter(
            (lineItem): lineItem is TourExtensionLineItem =>
                lineItem.type === "TourExtension",
        ) ?? [];

    return anyExtensionLineItems.pop() ?? null;
};
