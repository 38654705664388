import { useEffect, useRef, useState } from "react";
import { useResize } from "./useResize";

type UseContainerHeightResizeArgs = {
    /**
     * initial height of the container
     * @default 200
     */
    minHeight?: number;
};

/**
 * Updates the height of the ZAF app based on changing height of a
 * container div
 *
 * @param minHeight
 * @returns ref to be assigned to the container div
 */
export const useContainerHeightResize = ({
    minHeight = 200,
}: UseContainerHeightResizeArgs) => {
    const [containerHeight, setContainerHeight] = useState(minHeight);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            const entry = entries[0];
            if (entry) {
                setContainerHeight(entry.contentRect.height);
            }
        });

        if (containerRef.current) {
            observer.observe(containerRef.current);
            return () => {
                observer.disconnect();
            };
        }
    }, [containerRef]);

    useResize({
        width: "100%",
        height:
            // Add additional pixels to container height to remove the vert scrollbar
            `${Math.max(minHeight, containerHeight) + 32}px`,
    });

    return containerRef;
};
