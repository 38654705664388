import { Tag } from "@zendeskgarden/react-tags";
import { useTheme } from "styled-components";
import { ZendeskAppTrip } from "@localtypes/zendesk-app.types";

export type TravelReadyTagsProps = {
    tripData: ZendeskAppTrip;
};

export const TravelReadyTags: React.FC<TravelReadyTagsProps> = ({
    tripData,
}) => {
    const theme = useTheme();

    const green = theme.palette.green[600];
    const yellow = theme.palette.yellow[400];
    const grey = theme.palette.grey[200];

    const TCTitle = `Terms & Conditions: ${
        tripData.termsAndConditions ? "Completed" : "Not complete"
    }`;
    const TCColor = tripData.termsAndConditions ? green : grey;

    let TSATitle = "TSA: ";
    let TSAColor: string;
    if (tripData.hasCompletedTsaInformation) {
        TSATitle += "Completed";
        TSAColor = green;
    } else if (tripData.hasCompletedPassportInformation) {
        TSATitle += "Passport completed";
        TSAColor = yellow;
    } else if (tripData.hasCompletedTravelerDetails) {
        TSATitle += "Traveler Details completed";
        TSAColor = yellow;
    } else {
        TSATitle += "Not complete";
        TSAColor = grey;
    }

    let ECTitle = "EC: ";
    let ECColor: string;
    if (tripData.hasEmergencyContacts && tripData.hasCompletedOnTourContact) {
        ECTitle += "All Contacts completed";
        ECColor = green;
    } else if (tripData.hasEmergencyContacts) {
        ECTitle += "Emergency Contacts completed";
        ECColor = yellow;
    } else if (tripData.hasCompletedOnTourContact) {
        ECTitle += "On Tour Contacts completed";
        ECColor = yellow;
    } else {
        ECTitle += "Not complete";
        ECColor = grey;
    }

    const TDTitle = `Tour Director Notes: ${
        tripData.hasCompletedHealthNotes ? "Completed" : "Not complete"
    }`;
    const TDColor = tripData.hasCompletedHealthNotes ? green : grey;

    return (
        <div
            style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                gap: "4px",
            }}
        >
            <Tag title={TCTitle} hue={TCColor}>
                T&C
            </Tag>
            <Tag title={TSATitle} hue={TSAColor}>
                TSA
            </Tag>
            <Tag title={ECTitle} hue={ECColor}>
                EC
            </Tag>
            <Tag title={TDTitle} hue={TDColor}>
                TD
            </Tag>
        </div>
    );
};
