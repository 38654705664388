import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";

// We can't style the ReactQuill directly because its `theme` prop
// conflicts with the styled theme prop.  So style a wrapper div instead.
const StyledContainer = styled.div(({ theme }) => ({
    // Provide several basic text formatting styles missing from `quill.snow.css`
    "& .ql-snow .ql-editor strong": {
        fontWeight: theme.fontWeights.extrabold,
    },
    "& .ql-snow .ql-editor em": {
        fontStyle: "italic",
    },
    "& .ql-snow .ql-editor u": {
        textDecoration: "underline",
    },
    "& .ql-snow .ql-editor": {
        minHeight: "256px",
        fontSize: theme.fontSizes.md,
        fontFamily: theme.fonts.system,
    },
    "& .quill": {
        marginTop: "4px",
    },
    // Additional styles to fit with Zendesk
    "& .ql-toolbar.ql-snow": {
        borderRadius: `${theme.borderRadii.md} ${theme.borderRadii.md} 0 0`,
        borderColor: theme.colors.neutralHue[300],
        transition:
            "border 0.25s ease-in-out,box-shadow 0.1s ease-in-out,background-color 0.25s ease-in-out,color 0.25s ease-in-out,z-index 0.25s ease-in-out",
    },
    "& .ql-container.ql-snow": {
        borderRadius: `0 0 ${theme.borderRadii.md} ${theme.borderRadii.md}`,
        borderColor: theme.colors.neutralHue[300],
        transition:
            "border 0.25s ease-in-out,box-shadow 0.1s ease-in-out,background-color 0.25s ease-in-out,color 0.25s ease-in-out,z-index 0.25s ease-in-out",
        "&:hover": {
            borderColor: theme.colors.primaryHue[600],
        },
        "&:focus-within": {
            // rgba(31,115,183,0.35) is same hue as: theme.colors.primaryHue[600] but needs 0.35 alpha
            boxShadow: theme.shadows.md("rgba(31,115,183,0.35)"),
            borderColor: theme.colors.primaryHue[600],
            borderRadius: theme.borderRadii.md,
        },
    },
}));

const modules = {
    toolbar: [
        [{ header: [false, 1, 2, 3, 4] }],
        ["bold", "italic", "underline", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
    ],
};
const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "blockquote",
    "list",
    "bullet",
    "link",
];

export type RichTextEditorProps = {
    text: string;
    setText: React.Dispatch<React.SetStateAction<string>>;
    disabled: boolean;
};

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
    text,
    setText,
    disabled,
}) => {
    return (
        <StyledContainer>
            <ReactQuill
                readOnly={disabled}
                theme="snow"
                onChange={(val) => setText(val)}
                value={text}
                modules={modules}
                formats={formats}
            />
        </StyledContainer>
    );
};
