import { useZafGraphqlClient } from "@hooks/useZafGraphqlClient";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {
    CustomerDocument,
    CustomerQuery,
} from "@graphql/queries/generated/CustomerQuery";
import { Customer } from "@localtypes/generated-gql";

export const useZafCustomerQuery = (
    id: undefined | string,
): UseQueryResult<null | Customer> => {
    const zafGraphqlClient = useZafGraphqlClient();

    const queryFn = async (): Promise<null | CustomerQuery["customer"]> => {
        const response = await zafGraphqlClient?.execute<CustomerQuery>(
            CustomerDocument,
            { id },
        );
        return response?.customer ?? null;
    };

    return useQuery({
        queryKey: ["useZafCustomerQuery"],
        queryFn,
        enabled: !!(id && zafGraphqlClient),
    });
};
