import { Row } from "@zendeskgarden/react-grid";
import {
    DataCol,
    DataRow,
    LabelCol,
    StyledSectionHeaderCol,
    StyledSectionTopRow,
} from "./StyledGrid";
import { ZendeskCustomerFragment } from "@graphql/fragments/generated/CustomerFragment";

export type RequesterViewProps = {
    customer: null | ZendeskCustomerFragment;
};

export const RequesterView: React.FC<RequesterViewProps> = ({ customer }) => {
    return (
        <>
            <StyledSectionTopRow>
                <StyledSectionHeaderCol>Requester</StyledSectionHeaderCol>
            </StyledSectionTopRow>
            <Row>
                <LabelCol>Preferred name</LabelCol>
                <DataCol>
                    {customer?.firstName && customer?.lastName
                        ? `${customer?.firstName} ${customer?.lastName}`
                        : "–"}
                </DataCol>
            </Row>
            <DataRow>
                <LabelCol>ABE Customer ID</LabelCol>
                <DataCol>{customer?._id || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Phoenix Individual ID</LabelCol>
                <DataCol>
                    {customer?.legacyIds?.phxIndividualIds?.join(", ") ?? "–"}
                </DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Jasmine Customer ID</LabelCol>
                <DataCol>
                    {customer?.legacyIds?.jasmineCustomerId ?? "–"}
                </DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Primary email</LabelCol>
                <DataCol>{customer?.email || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Primary phone</LabelCol>
                <DataCol>{customer?.phoneNumber || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Address</LabelCol>
                <DataCol>
                    {customer?.address?.street1 || "–"}
                    <br />
                    {customer?.address?.street2 || "–"}
                    <br />
                    {customer?.address?.city || "–"}
                    {!!customer?.address?.city && !!customer?.address?.state
                        ? ", "
                        : null}
                    {customer?.address?.state || "–"}{" "}
                    {customer?.address?.postalCode || "–"}
                    <br />
                    {customer?.address?.country || "–"}
                </DataCol>
            </DataRow>
            {/*<DataRow>*/}
            {/*    <LabelCol>Loyalty level</LabelCol>*/}
            {/*    <DataCol>*/}
            {/*        {customer?.loyaltyInfo?.loyaltyLevelName || "–"}*/}
            {/*    </DataCol>*/}
            {/*</DataRow>*/}
            {/*<DataRow>*/}
            {/*    <LabelCol>Past tours</LabelCol>*/}
            {/*    <DataCol>*/}
            {/*        {customer?.loyaltyInfo?.traveledLoyaltyTrips || "–"}*/}
            {/*    </DataCol>*/}
            {/*</DataRow>*/}
        </>
    );
};
