import { Col, Row } from "@zendeskgarden/react-grid";
import styled from "styled-components";

export const DataRow = styled(Row)(({ theme }) => ({
    marginTop: theme.space["sm"],
}));

export const LabelCol = styled(Col).attrs({
    xs: 5,
})(({ theme }) => ({
    color: theme.palette[theme.colors.neutralHue][600],
    fontSize: theme.fontSizes.md,
    fontWeight: theme.fontWeights.regular,
    textAlign: "left",
    maxWidth: "160px",
}));

export const DataCol = styled(Col)(({ theme }) => ({
    fontSize: theme.fontSizes.md,
    fontWeight: theme.fontWeights.semibold,
    textAlign: "left",
}));

export const StyledSectionTopRow = styled(Row)(({ theme }) => ({
    marginBlock: theme.space.md,
}));

export const StyledSectionHeaderCol = styled(Col)(({ theme }) => ({
    fontWeight: theme.fontWeights.semibold,
}));

export const HR = styled.hr(({ theme }) => ({
    color: theme.palette.grey[200],
    marginBlock: theme.space.md,
}));
