import dayjs from "dayjs";
import {
    DataCol,
    DataRow,
    LabelCol,
    StyledSectionHeaderCol,
    StyledSectionTopRow,
} from "./StyledGrid";
import { DetailsComponentProps } from "./TourDetailsView";

export const TsaDetails: React.FC<DetailsComponentProps> = ({ trip }) => {
    return (
        <>
            <StyledSectionTopRow>
                <StyledSectionHeaderCol>TSA</StyledSectionHeaderCol>
            </StyledSectionTopRow>
            <DataRow>
                <LabelCol>First name</LabelCol>
                <DataCol>{trip.tsaInformation?.firstName || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Middle name</LabelCol>
                <DataCol>{trip.tsaInformation?.middleName || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Last name</LabelCol>
                <DataCol>{trip.tsaInformation?.lastName || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Suffix</LabelCol>
                <DataCol>{trip.tsaInformation?.suffix || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Gender</LabelCol>
                <DataCol>{trip.tsaInformation?.gender || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>DOB</LabelCol>
                <DataCol>
                    {trip.tsaInformation?.dateOfBirth
                        ? dayjs
                              .utc(trip.tsaInformation?.dateOfBirth)
                              .format("MM/DD/YYYY")
                        : "–"}
                </DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>ID</LabelCol>
                <DataCol>
                    {trip.tsaInformation?.maskedIdentityDocumentNumber || "–"}
                </DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Expiration</LabelCol>
                <DataCol>
                    {trip.tsaInformation?.expirationDate
                        ? dayjs
                              .utc(trip.tsaInformation?.expirationDate)
                              .format("MM/DD/YYYY")
                        : "–"}
                </DataCol>
            </DataRow>
        </>
    );
};
