import React from "react";
import {
    DataCol,
    DataRow,
    LabelCol,
    StyledSectionHeaderCol,
    StyledSectionTopRow,
} from "./StyledGrid";
import { DetailsComponentProps } from "./TourDetailsView";
import { formatDates } from "./formatDates";
import { ZendeskAppTrip } from "@localtypes/zendesk-app.types";

type ExtendedStay =
    | (ZendeskAppTrip["preStay"] & {
          typeLabel: "Prestay" | "Poststay" | undefined;
      })
    | undefined;

export const ExtendedStayDetails: React.FC<DetailsComponentProps> = ({
    trip,
}) => {
    const extendedStays: ExtendedStay[] = [];
    if (!trip.preStay && !trip.postStay) extendedStays.push(undefined);
    if (trip.preStay)
        extendedStays.push({ ...trip.preStay, typeLabel: "Prestay" });
    if (trip.postStay)
        extendedStays.push({ ...trip.postStay, typeLabel: "Poststay" });

    return (
        <React.Fragment>
            <StyledSectionTopRow>
                <StyledSectionHeaderCol>Extended stays</StyledSectionHeaderCol>
            </StyledSectionTopRow>

            {extendedStays.map((extendedStay) => (
                <React.Fragment key={extendedStay?.id ?? ""}>
                    <DataRow>
                        <LabelCol>Request type</LabelCol>
                        <DataCol>{extendedStay?.typeLabel || "–"}</DataCol>
                    </DataRow>
                    <DataRow>
                        <LabelCol>Dates requested</LabelCol>
                        <DataCol>
                            {formatDates(
                                extendedStay?.startDate,
                                extendedStay?.endDate,
                            )}
                        </DataCol>
                    </DataRow>
                    <DataRow>
                        <LabelCol>City requested</LabelCol>
                        <DataCol>{extendedStay?.cityName || "–"}</DataCol>
                    </DataRow>
                </React.Fragment>
            ))}
        </React.Fragment>
    );
};
