import { useZafClient } from "@hooks/useZafClient";
import { useDebounce } from "use-debounce";
import { z } from "zod";
import { QueryFunctionContext, useQuery } from "@tanstack/react-query";

export const customersSearchResponseSchema = z.object({
    users: z.array(
        z
            .object({
                id: z.number(),
                name: z.string(),
                email: z.string().nullable(),
            })
            .transform((val) => ({
                name: val.name,
                email: val.email ?? "",
                customerId: val.id, // rename this property for consistency with Customer type
            })),
    ),
});

export type CustomerSearchResult = z.output<
    typeof customersSearchResponseSchema.shape.users.element
>;

export const useCustomerAutocomplete = (input: string) => {
    const zafClient = useZafClient();

    const [debouncedInput] = useDebounce(input, 200);

    const queryFn = async ({ queryKey }: QueryFunctionContext) => {
        if (!zafClient) {
            return [];
        }
        const response = await zafClient.request({
            url: `/api/v2/users/autocomplete.json`,
            type: "GET",
            contentType: "application/json",
            data: {
                name: queryKey[1],
            },
        });
        return customersSearchResponseSchema.parse(response).users ?? [];
    };

    return useQuery({
        queryKey: ["customerAutocomplete", debouncedInput],
        queryFn,
        staleTime: 60000,
    });
};
