import {
    infoDisplayOptions,
    InfoDisplayOptions,
} from "@components/tour-details-view";
import { Button } from "@zendeskgarden/react-buttons";
import {
    Dropdown,
    Field,
    Item,
    Label,
    Menu,
    Multiselect,
} from "@zendeskgarden/react-dropdowns";
import { Tag } from "@zendeskgarden/react-tags";
import { useState } from "react";
import styled from "styled-components";

const StyledFlexRow = styled.div({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: "4px",
});
const StyledSmallButton = styled(Button)({
    height: "inherit",
    lineHeight: "inherit",
});

export type InfoDisplayMultiselectProps = {
    options: InfoDisplayOptions[];
    setOptions: React.Dispatch<React.SetStateAction<InfoDisplayOptions[]>>;
};

export const InfoDisplayMultiselect: React.FC<InfoDisplayMultiselectProps> = ({
    options,
    setOptions,
}) => {
    const [inputValue, setInputValue] = useState("");
    const [matchingOptions, setMatchingOptions] = useState<
        InfoDisplayOptions[]
    >([]);

    const isAllOptions = options.length === infoDisplayOptions.length;

    const handleAddAllOptions = () => {
        setOptions([...infoDisplayOptions]);
    };

    const handleInputValueChange = (value: string) => {
        setInputValue(value);

        // autocomplete for the multiselect
        const matchedOptions = infoDisplayOptions.filter((option) => {
            return (
                option
                    .trim()
                    .toLowerCase()
                    .indexOf(value.trim().toLowerCase()) !== -1
            );
        });
        setMatchingOptions(matchedOptions);
    };

    const renderOptions = () => {
        // If we matched any options, then show the autocomplete list
        if (matchingOptions.length !== 0) {
            return matchingOptions.map((option) => (
                <Item key={option} value={option}>
                    <span>{option}</span>
                </Item>
            ));
        }

        // Present all the options
        return infoDisplayOptions.map((option) => (
            <Item key={option} value={option}>
                <span>{option}</span>
            </Item>
        ));
    };

    return (
        <Dropdown
            inputValue={inputValue}
            selectedItems={options}
            onSelect={(items) => setOptions(items)}
            downshiftProps={{ defaultHighlightedIndex: 0 }}
            onInputValueChange={handleInputValueChange}
        >
            <Field>
                <StyledFlexRow>
                    <Label>Info to display</Label>
                    <StyledSmallButton
                        isBasic
                        size="small"
                        disabled={isAllOptions}
                        onClick={handleAddAllOptions}
                    >
                        Select all
                    </StyledSmallButton>
                </StyledFlexRow>
                <Multiselect
                    isCompact
                    renderItem={({
                        value,
                        removeValue,
                    }: {
                        value: string;
                        removeValue: () => void;
                    }) => (
                        <Tag>
                            <span>{value}</span>
                            <Tag.Close onClick={() => removeValue()} />
                        </Tag>
                    )}
                />
            </Field>
            <Menu>{renderOptions()}</Menu>
        </Dropdown>
    );
};
