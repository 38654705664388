import { getAtlasProdRequestsLink } from "@components/copy-tour-to-clipboard-button/CopyTourToClipboardButton";
import { TravelReadyTags } from "@components/travel-ready-tags";
import dayjs from "dayjs";
import {
    DataCol,
    DataRow,
    LabelCol,
    StyledSectionHeaderCol,
    StyledSectionTopRow,
} from "./StyledGrid";
import { DetailsComponentProps } from "./TourDetailsView";
import { formatDates } from "./formatDates";

export const PrimaryDetails: React.FC<DetailsComponentProps> = ({ trip }) => {
    return (
        <>
            <StyledSectionTopRow>
                <StyledSectionHeaderCol>Primary</StyledSectionHeaderCol>
            </StyledSectionTopRow>
            <DataRow>
                <LabelCol>Prod ID</LabelCol>
                <DataCol>{trip.departure?.atlasProdTourId || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Sales Tour ID</LabelCol>
                <DataCol>{trip.departure?.atlasSalesTourId || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Tour code</LabelCol>
                <DataCol>{trip.departure?.tourCode || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Tour date</LabelCol>
                <DataCol>
                    {formatDates(
                        trip.departure?.departureDate,
                        trip.departure?.endDate,
                    )}
                </DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Extension</LabelCol>
                <DataCol>
                    {trip.extensionSelected &&
                    trip.departure?.extensionDeparture?.name
                        ? trip.departure?.extensionDeparture?.name
                        : "–"}
                </DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Extension date</LabelCol>
                <DataCol>
                    {formatDates(
                        trip.departure?.extensionDeparture?.startDate,
                        trip.departure?.extensionDeparture?.endDate,
                    )}
                </DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>TFD</LabelCol>
                <DataCol>
                    {trip.departure?.tourFinalizationDate
                        ? dayjs
                              .utc(trip.departure?.tourFinalizationDate)
                              .format("MMM D, YYYY")
                        : "–"}
                </DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Ready</LabelCol>
                <DataCol>
                    <TravelReadyTags tripData={trip} />
                </DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Tour status</LabelCol>
                <DataCol>{trip.departure?.status || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Accommodation level</LabelCol>
                <DataCol>
                    {trip.departure?.tourContent?.accommodationLevel?.title ||
                        "–"}
                </DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Prod requests</LabelCol>
                <DataCol>
                    {trip.departure?.atlasSalesTourId?.length ? (
                        <a
                            href={getAtlasProdRequestsLink(
                                trip.departure.atlasSalesTourId,
                            )}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {trip.departure.atlasSalesTourId}
                        </a>
                    ) : (
                        "-"
                    )}
                </DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Trip status</LabelCol>
                <DataCol>{trip.status || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Customer type</LabelCol>
                <DataCol>{trip.customerType}</DataCol>
            </DataRow>
            {trip.groupTrip?.groupLeaderCustomer ? (
                <DataRow>
                    <LabelCol>Group coordinator</LabelCol>
                    <DataCol>
                        {trip.groupTrip?.groupLeaderCustomer.firstName}{" "}
                        {trip.groupTrip?.groupLeaderCustomer.lastName}
                    </DataCol>
                </DataRow>
            ) : null}
            {trip.hasAutoPay === true ? (
                <DataRow>
                    <LabelCol>Autopay</LabelCol>
                    <DataCol>Yes</DataCol>
                </DataRow>
            ) : null}
            <DataRow>
                <LabelCol>Balance</LabelCol>
                <DataCol>
                    {trip.order?.balance
                        ? new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                          }).format(trip.order?.balance)
                        : "–"}
                </DataCol>
            </DataRow>
        </>
    );
};
