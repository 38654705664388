import * as Types from '../../../types/generated-gql';

import { gql } from '@apollo/client';
export type ZendeskCustomerFragment = { __typename?: 'Customer', _id: string, firstName?: string | null, lastName?: string | null, phoneNumber?: any | null, email?: any | null, address?: { __typename?: 'Address', street1: string, street2?: string | null, city: string, state: string, postalCode: string, country: string } | null, legacyIds?: { __typename?: 'LegacyCustomerIds', phxIndividualIds?: Array<string> | null, jasmineCustomerId?: string | null } | null };

export const ZendeskCustomerFragmentDoc = gql`
    fragment ZendeskCustomer on Customer {
  _id
  firstName
  lastName
  phoneNumber
  email
  address {
    street1
    street2
    city
    state
    postalCode
    country
  }
  legacyIds {
    phxIndividualIds
    jasmineCustomerId
  }
}
    `;