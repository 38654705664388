import { Route, Routes } from "react-router-dom";
import { TicketManagerView } from "./screens/ticket-manager-sidebar";

export const TicketManager: React.FC = () => {
    return (
        <Routes>
            <Route
                path="ticket_sidebar"
                element={<TicketManagerView location="ticket_sidebar" />}
            />
            <Route
                path="user_sidebar"
                element={<TicketManagerView location="user_sidebar" />}
            />
        </Routes>
    );
};
