import { BrowserRouter, Route, Routes } from "react-router-dom";
import { BulkTickets } from "./apps/bulk-tickets";
import { InvoiceLink } from "./apps/invoice-link";
import { RequesterDetails } from "./apps/requester-details";
import { TicketManager } from "./apps/ticket-manager";
import { TourDetails } from "./apps/tour-details";

export const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/tour-details/*" element={<TourDetails />} />
                <Route path="/bulk-tickets/*" element={<BulkTickets />} />
                <Route path="/invoice-link/*" element={<InvoiceLink />} />
                <Route path="/ticket-manager/*" element={<TicketManager />} />
                <Route
                    path="/requester-details/*"
                    element={<RequesterDetails />}
                />
            </Routes>
        </BrowserRouter>
    );
};
