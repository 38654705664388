import { useZafGraphqlClient } from "@hooks/useZafGraphqlClient";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {
    DepartureWithBookedTripsDocument,
    DepartureWithBookedTripsQuery,
} from "@graphql/queries/generated/DepartureWithBookedTripsQuery";
import { BookingTrip, BusinessCode, Scalars } from "@localtypes/generated-gql";
import { useCallback, useState } from "react";

export type DepartureWithBookedTrips = {
    _id: Scalars["ObjectId"];
    bookedTrips: BookingTrip[];
};

type FetchCallback = (id: undefined | string) => void;

export const useZafDepartureWithBookedTripsQuery = (
    id: undefined | string,
    businessCode: undefined | BusinessCode,
    enabled = true,
): UseQueryResult<null | DepartureWithBookedTrips> => {
    const zafGraphqlClient = useZafGraphqlClient();

    const queryFn = async (): Promise<
        null | DepartureWithBookedTripsQuery["departure"]
    > => {
        const response =
            await zafGraphqlClient?.execute<DepartureWithBookedTripsQuery>(
                DepartureWithBookedTripsDocument,
                { id, businessCode },
            );
        return response?.departure ?? null;
    };

    return useQuery({
        queryKey: ["useZafDepartureWithBookedTripsQuery"],
        queryFn,
        enabled: !!(enabled && id && businessCode && zafGraphqlClient),
    });
};

export const useZafDepartureWithBookedTripsLazyQuery = (
    id: undefined | string,
    businessCode: undefined | BusinessCode,
): readonly [
    FetchCallback,
    UseQueryResult<null | DepartureWithBookedTrips>,
] => {
    const [enabled, setEnabled] = useState(false);

    const query: UseQueryResult<null | DepartureWithBookedTrips> =
        useZafDepartureWithBookedTripsQuery(id, businessCode, enabled);

    return [
        useCallback<FetchCallback>(() => setEnabled(true), []),
        query,
    ] as const;
};
