import {
    DataCol,
    DataRow,
    LabelCol,
    StyledSectionHeaderCol,
    StyledSectionTopRow,
} from "./StyledGrid";
import { DetailsComponentProps } from "./TourDetailsView";

export const RoomingDetails: React.FC<DetailsComponentProps> = ({ trip }) => {
    return (
        <>
            <StyledSectionTopRow>
                <StyledSectionHeaderCol>Rooming</StyledSectionHeaderCol>
            </StyledSectionTopRow>
            <DataRow>
                <LabelCol>Roommate</LabelCol>
                <DataCol>
                    {trip?.roommateCustomers &&
                    trip.roommateCustomers.length > 0
                        ? trip.roommateCustomers
                              .map(
                                  (roommate) =>
                                      `${roommate.firstName} ${roommate.lastName}`,
                              )
                              .join(", ")
                        : !!trip.requestedRoommateNames &&
                          trip.requestedRoommateNames?.length > 0
                        ? trip.requestedRoommateNames?.join(", ")
                        : "–"}
                </DataCol>
            </DataRow>

            <DataRow>
                <LabelCol>Room type</LabelCol>
                <DataCol>{trip.departureRoom?.roomName || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Bed configuration</LabelCol>
                <DataCol>{trip.departureRoom?.bedConfiguration || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Cabin code</LabelCol>
                <DataCol>{trip.departureRoom?.cabinCode || "–"}</DataCol>
            </DataRow>
        </>
    );
};
