import { Spinner as ZdSpinner } from "@zendeskgarden/react-loaders";
import styled from "styled-components";

const CenterDiv = styled.div({
    display: "flex",
    justifyContent: "center",
    marginTop: 16,
});

export const Spinner = () => (
    <CenterDiv>
        <ZdSpinner size="16" delayMS={0} />
    </CenterDiv>
);
