import dayjs from "dayjs";
import { DataCol, DataRow, LabelCol } from "./StyledGrid";
import { ZendeskAppTrip } from "@localtypes/zendesk-app.types";

export type FlightRowsProps = {
    trip: ZendeskAppTrip;
};

export const FlightRows: React.FC<FlightRowsProps> = ({ trip }) => {
    const flightsInfo = trip.flightsRequested;

    if (trip.isLandOnly) {
        return (
            <>
                <DataRow>
                    <LabelCol>Status</LabelCol>
                    <DataCol>LO1</DataCol>
                </DataRow>
                <DataRow>
                    <LabelCol>Gateway</LabelCol>
                    <DataCol>-</DataCol>
                </DataRow>
            </>
        );
    }

    if (!flightsInfo) {
        return (
            <DataRow>
                <LabelCol>Status</LabelCol>
                <DataCol>–</DataCol>
            </DataRow>
        );
    }

    const departureFlight = flightsInfo.departureFlight;
    const returnFlight = flightsInfo.returnFlight;

    return (
        <>
            <DataRow>
                <LabelCol>
                    {departureFlight.startDate
                        ? dayjs
                              .utc(departureFlight.startDate)
                              .format("MMM D, YYYY")
                        : "–"}
                </LabelCol>
                <DataCol>
                    {departureFlight.originCode ?? "-"}
                    {" > "}
                    {departureFlight.destinationCode ?? "-"}
                </DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>
                    {returnFlight.startDate
                        ? dayjs
                              .utc(returnFlight.startDate)
                              .format("MMM D, YYYY")
                        : "–"}
                </LabelCol>
                <DataCol>
                    {returnFlight.originCode ?? "-"}
                    {" > "}
                    {returnFlight.destinationCode ?? "-"}
                </DataCol>
            </DataRow>
        </>
    );
};
