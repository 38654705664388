import { CustomerBrandDropdown } from "@components/customer-brand-dropdown";
import { CustomerTripsDropdown } from "@components/customer-trips-dropdown";
import { InfoDisplayOptions } from "@components/tour-details-view";
import { TravelersMultiselect } from "@components/travelers-multiselect";
import { useTicket } from "@hooks/useTicket";
import { ZafAppLocation } from "@localtypes/zafTypes";
import { Button } from "@zendeskgarden/react-buttons";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import { Dots } from "@zendeskgarden/react-loaders";
import { InfoDisplayMultiselect } from "./InfoTypeMultiselect";
import { ZendeskAppTrip } from "@localtypes/zendesk-app.types";
import { BusinessCode } from "@localtypes/generated-gql";
import { updateTicketFields } from "@hooks/updateTicketFields";
import { useZafClient } from "@hooks/useZafClient";
import { useZafGraphqlClient } from "@hooks/useZafGraphqlClient";

export type ConfigViewProps = {
    customerId: string;
    requesterTripId: string | undefined;
    setRequesterTripId: React.Dispatch<
        React.SetStateAction<string | undefined>
    >;
    travelersTripIds: string[];
    setTravelersTripIds: React.Dispatch<React.SetStateAction<string[]>>;
    displayOptions: InfoDisplayOptions[];
    setDisplayOptions: React.Dispatch<
        React.SetStateAction<InfoDisplayOptions[]>
    >;
    onSubmitConfig: () => void;
    loadingTrips?: boolean;
    selectedCustomerBrand: BusinessCode;
    inUserSidebar: boolean;
    hasMultipleIds: boolean;
    setSelectedCustomerBrand: React.Dispatch<
        React.SetStateAction<BusinessCode>
    >;
    location: ZafAppLocation;
    customerTrips: ZendeskAppTrip[];
    tourTravelers: ZendeskAppTrip[];
};

export const ConfigView: React.FC<ConfigViewProps> = ({
    customerId,
    requesterTripId,
    setRequesterTripId,
    travelersTripIds,
    setTravelersTripIds,
    displayOptions,
    setDisplayOptions,
    onSubmitConfig,
    loadingTrips,
    selectedCustomerBrand,
    inUserSidebar,
    hasMultipleIds,
    setSelectedCustomerBrand,
    location,
    customerTrips,
    tourTravelers,
}) => {
    const zafClient = useZafClient();
    const zafGraphqlClient = useZafGraphqlClient();

    const { isParentTicket, fieldData: ticketFieldData } = useTicket(location);

    return (
        <Grid>
            {inUserSidebar && hasMultipleIds && (
                <Row style={{ marginTop: 12 }}>
                    <Col>
                        <CustomerBrandDropdown
                            selectedCustomerBrand={selectedCustomerBrand}
                            setSelectedCustomerBrand={setSelectedCustomerBrand}
                        />
                    </Col>
                </Row>
            )}
            <Row data-testid="customerTripsDropdown" style={{ marginTop: 12 }}>
                <Col>
                    <CustomerTripsDropdown
                        customerId={customerId}
                        selectedTripId={requesterTripId}
                        setSelectedTripId={setRequesterTripId}
                        isLabelVisible={true}
                        labelText="Requester's tour"
                        inUserSidebar={inUserSidebar}
                        location={location}
                        trips={customerTrips}
                    />
                </Col>
            </Row>
            {Boolean(requesterTripId) && (
                <>
                    <Row
                        data-testid="travelersMultiselect"
                        style={{ marginTop: 20 }}
                    >
                        <Col>
                            {requesterTripId && (
                                <TravelersMultiselect
                                    requesterTripId={requesterTripId}
                                    travelersTripIds={travelersTripIds}
                                    setTravelersTripIds={setTravelersTripIds}
                                    trips={tourTravelers}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row
                        data-testid="displayOptionsMultiselect"
                        style={{ marginTop: 20 }}
                    >
                        <Col>
                            <InfoDisplayMultiselect
                                options={displayOptions}
                                setOptions={setDisplayOptions}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "12px",
                                paddingTop: "20px",
                                flexWrap: "wrap",
                            }}
                        >
                            <Button
                                isPrimary
                                size="small"
                                type="submit"
                                disabled={
                                    !travelersTripIds.length ||
                                    !displayOptions.length
                                }
                                onClick={onSubmitConfig}
                            >
                                {loadingTrips && (
                                    <Button.StartIcon>
                                        <Dots size={32} delayMS={0} />
                                    </Button.StartIcon>
                                )}
                                Submit
                            </Button>
                            {isParentTicket ? (
                                <Button
                                    disabled={
                                        ticketFieldData?.tripId ===
                                        requesterTripId
                                    }
                                    size="small"
                                    onClick={() =>
                                        updateTicketFields(
                                            zafClient,
                                            zafGraphqlClient,
                                            requesterTripId ?? "",
                                        ).then(() => window.location.reload())
                                    }
                                >
                                    Link selected tour to ticket
                                </Button>
                            ) : null}
                        </Col>
                    </Row>
                </>
            )}
        </Grid>
    );
};
