import { Grid } from "@zendeskgarden/react-grid";
import { sortBy } from "lodash";
import React from "react";
import { ExtendedStayDetails } from "./ExtendedStayDetails";
import { FlightDetails } from "./FlightDetails";
import { HealthDetails } from "./HealthDetails";
import { PrimaryDetails } from "./PrimaryDetails";
import { RoomingDetails } from "./RoomingDetails";
import { HR } from "./StyledGrid";
import { TourDetailsButtonRow } from "./TourDetailsButtonRow";
import { TransferDetails } from "./TransferDetails";
import { TsaDetails } from "./TsaDetails";
import { ZendeskAppTrip } from "@localtypes/zendesk-app.types";

export type DetailsComponentProps = { trip: ZendeskAppTrip };

export const infoDisplayOptions = [
    "Primary tour details",
    "TSA",
    "Flights",
    "Transfers",
    "Rooming",
    "Extended Stays",
    "Health",
] as const;
export type InfoDisplayOptions = typeof infoDisplayOptions[number];
export type LinkTicketButtonState = "Enabled" | "Disabled" | "Hidden";

const infoDisplayOptionsMap: Record<
    InfoDisplayOptions,
    React.FC<DetailsComponentProps>
> = {
    "Primary tour details": PrimaryDetails,
    TSA: TsaDetails,
    Flights: FlightDetails,
    Transfers: TransferDetails,
    Rooming: RoomingDetails,
    "Extended Stays": ExtendedStayDetails,
    Health: HealthDetails,
} as const;

export type TourDetailsViewProps = {
    trip: ZendeskAppTrip | undefined | null;
    options?: InfoDisplayOptions[];
    showNameOnOrderButton?: boolean;
    showCopyTourToClipboardButton?: boolean;
    showBottomDivider?: boolean;
    requesterTripId: string;
    businessId: string;
    linkTicketButtonState: LinkTicketButtonState;
};

export const TourDetailsView: React.FC<TourDetailsViewProps> = ({
    trip,
    options,
    showNameOnOrderButton = false,
    showCopyTourToClipboardButton = false,
    showBottomDivider = false,
    requesterTripId,
    businessId,
    linkTicketButtonState,
}) => {
    if (!trip) {
        return null;
    }

    const sortedOptions = sortBy(
        // If options isn't provided, default to all display options
        !options ? infoDisplayOptions : options,
        [(option) => infoDisplayOptions.indexOf(option)],
    );

    const showDetailSection = (option: InfoDisplayOptions) =>
        sortedOptions.includes(option);

    const showHR = (option: InfoDisplayOptions) => {
        const sortedOptionIdx = sortedOptions.indexOf(option);
        return (
            sortedOptionIdx >= 0 &&
            sortedOptionIdx <
                sortedOptions?.length - (showBottomDivider ? 0 : 1)
        );
    };

    return (
        <>
            <Grid>
                <TourDetailsButtonRow
                    trip={trip}
                    showNameOnOrderButton={showNameOnOrderButton}
                    showCopyTourToClipboardButton={
                        showCopyTourToClipboardButton
                    }
                    requesterTripId={requesterTripId}
                    linkTicketButtonState={linkTicketButtonState}
                    showOpenOrderButton={
                        businessId === import.meta.env.VITE_GAT_BUSINESS_ID
                    }
                />
                {sortedOptions.map((option) => {
                    const Component = infoDisplayOptionsMap[option];
                    return (
                        <React.Fragment key={option}>
                            {showDetailSection(option) && (
                                <Component trip={trip} />
                            )}
                            {showHR(option) && <HR />}
                        </React.Fragment>
                    );
                })}
            </Grid>
        </>
    );
};
