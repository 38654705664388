import { ZendeskAppTrip } from "@localtypes/zendesk-app.types";
import { ZendeskAppTripMapper } from "@hooks/ZendeskAppTripMapper";
import {
    useZafBookingTripQuery,
    useZafCustomerWithBookingTripsQuery,
    useZafDepartureWithBookedTripsLazyQuery,
    useZafDepartureWithBookedTripsQuery,
} from "@graphql/zaf-graphql-client";
import { BusinessCode } from "@localtypes/generated-gql";

type UseTourDetailsDataArgs = {
    customerId?: string;
    requesterTripId?: string;
    selectedTravelerTripId?: string;
    businessId: string | undefined;
    businessCode: BusinessCode | undefined;
};

/**
 * This hook is used to fetch data for the Tour Details page. It will work for the new booking engine, Jamsine and Phoenix.
 */
export const useTourDetailsData = ({
    businessCode,
    customerId,
    requesterTripId,
    selectedTravelerTripId,
}: UseTourDetailsDataArgs) => {
    const {
        data: customerWithBookingTripsQueryResult,
        isLoading: customerWithBookingTripsQueryLoading,
    } = useZafCustomerWithBookingTripsQuery(customerId, businessCode);
    const customerTrips =
        customerWithBookingTripsQueryResult?.bookingTrips?.map(
            ZendeskAppTripMapper.map,
        ) ?? [];
    const firstConfirmedUpcomingCustomerTrip = customerTrips[0];

    // Query for a BookingTrip by Trip ID explicitly specified on the ticket.
    const {
        data: selectedTravellerBookingTrip,
        isLoading: selectedTravellerBookingTripLoading,
    } = useZafBookingTripQuery(
        selectedTravelerTripId || firstConfirmedUpcomingCustomerTrip?.id,
    );
    const selectedTravellerZendeskAppTrip = ZendeskAppTripMapper.map(
        selectedTravellerBookingTrip ?? null,
    );

    // Query for all BookingTrips of requesters Departure.
    const {
        data: requesterBookingTripQueryResult,
        isLoading: requesterBookingTripQueryLoading,
    } = useZafBookingTripQuery(requesterTripId);
    const departureId = requesterBookingTripQueryResult?.departure?._id;
    const {
        data: allTripsOfRequesterDeparture,
        isLoading: allTripsOfRequesterDepartureLoading,
    } = useZafDepartureWithBookedTripsQuery(
        departureId || firstConfirmedUpcomingCustomerTrip?.departure?.id,
        businessCode,
    );
    const selectTrips: ZendeskAppTrip[] =
        allTripsOfRequesterDeparture?.bookedTrips?.map(
            ZendeskAppTripMapper.map,
        ) ?? [];

    // Lazy query for confirmed Trips of a Departure base on different filters.
    const [
        queryDepartureWithBookedTripsFunction,
        {
            data: departureWithBookedTrips,
            isLoading: departureWithBookedTripsLoading,
        },
    ] = useZafDepartureWithBookedTripsLazyQuery(departureId, businessCode);
    const displayedTrips: ZendeskAppTrip[] =
        departureWithBookedTrips?.bookedTrips?.map(ZendeskAppTripMapper.map) ??
        [];

    return {
        queryDepartureWithBookedTripsFunction,
        customerTrip: selectedTravellerZendeskAppTrip,
        customerTrips,
        selectTripDepartureId: departureId,
        selectTrips,
        displayedTrips,
        departureWithBookedTripsQueryLoading: departureWithBookedTripsLoading,
        loading:
            customerWithBookingTripsQueryLoading ||
            requesterBookingTripQueryLoading ||
            allTripsOfRequesterDepartureLoading ||
            departureWithBookedTripsLoading ||
            selectedTravellerBookingTripLoading,
    };
};
