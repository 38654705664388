import { useZafClient } from "@hooks/useZafClient";
import { useQuery } from "@tanstack/react-query";
import pluralize from "pluralize";
import { useMemo } from "react";
import { customersSearchResponseSchema } from "./useCustomerAutocomplete";

export const useCustomerEmailSearch = (emails: string[], enabled: boolean) => {
    const zafClient = useZafClient();

    const { data: customers, isFetching } = useQuery({
        queryKey: ["emailSearch", emails.join(" ")],
        enabled,
        staleTime: 60000,
        queryFn: async () => {
            if (!zafClient) {
                throw new Error("Internal error: Invalid zafClient");
            }
            if (emails.length < 1) {
                throw new Error("Must provide at least 1 email address");
            }

            const response = await zafClient.request({
                url: `/api/v2/users/search.json`,
                type: "GET",
                contentType: "application/json",
                data: {
                    query:
                        "type:user " +
                        emails.map((email) => `email:${email}`).join(" "),
                },
            });
            const customerResponseParse =
                customersSearchResponseSchema.parse(response);

            return customerResponseParse.users;
        },
    });

    // Check whether ALL the emails resolved to Zendesk customers
    const { validCustomersMsg, isValidCustomers } = useMemo((): {
        validCustomersMsg: string;
        isValidCustomers: boolean;
    } => {
        let validCustomersMsg = "";
        let isValidCustomers = false;

        if (!customers) {
            return {
                validCustomersMsg: "No valid customers",
                isValidCustomers: false,
            };
        }

        // Did any of the emails not resolve to a customer
        const nonZdEmails = emails.reduce<string[]>(
            (non, email) =>
                customers.some((initEmail) => initEmail.email === email)
                    ? non
                    : [...non, email],
            [],
        );

        if (nonZdEmails.length) {
            validCustomersMsg = `Zendesk customers do not exist for the following ${pluralize(
                "email",
                nonZdEmails.length,
                true,
            )}: ${nonZdEmails.join(" ")}`;
            isValidCustomers = false;
        } else {
            validCustomersMsg = `${customers?.length} valid ${pluralize(
                "customer",
                customers?.length,
            )}`;
            isValidCustomers = true;
        }

        return {
            isValidCustomers,
            validCustomersMsg,
        };
    }, [customers, emails]);

    return {
        // Since we initially disable the query, isLoading would be
        // initially true, so use isFetching instead
        isLoading: !customers && isFetching,
        customers,
        isValidCustomers, // all emails are valid customers
        validCustomersMsg,
    };
};
