import { useState } from "react";
import {
    Dropdown,
    Multiselect,
    Field,
    Menu,
    Item,
    Label,
    ItemMeta,
} from "@zendeskgarden/react-dropdowns";
import { Tag } from "@zendeskgarden/react-tags";
import {
    useCustomerAutocomplete,
    CustomerSearchResult,
} from "./hooks/useCustomerAutocomplete";
import { CustomerImportModal } from "./CustomerImportModal";
import styled from "styled-components";
import { Message } from "@zendeskgarden/react-forms";
import { Button } from "@zendeskgarden/react-buttons";

const StyledFlexRow = styled.div({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
});

export type CustomerMultiselectProps = {
    customers: CustomerSearchResult[];
    setCustomers: React.Dispatch<React.SetStateAction<CustomerSearchResult[]>>;
    disabled: boolean;
};

export const CustomerMultiselect: React.FC<CustomerMultiselectProps> = ({
    customers,
    setCustomers,
    disabled,
}) => {
    const [inputValue, setInputValue] = useState("");
    const [isImportModalVisible, setIsImportModalVisible] = useState(false);
    const {
        data: zdCustomers,
        isLoading: autocompleteLoading,
        error: autocompleteError,
    } = useCustomerAutocomplete(inputValue);

    // Display the items in the dropdown menu
    const menuRenderOptions = () => {
        if (autocompleteLoading) {
            return <Item disabled>Loading items...</Item>;
        }
        if (autocompleteError) {
            console.error(
                "Error in loading autocomplete items",
                autocompleteError,
            );
            return <Item disabled>Error loading items...</Item>;
        }

        if (!zdCustomers || zdCustomers.length === 0) {
            return <Item disabled>No matches found</Item>;
        }

        return zdCustomers.map((option) => (
            <Item key={option.customerId} value={option}>
                <span>{option.name}</span>
                <ItemMeta>{option.email}</ItemMeta>
            </Item>
        ));
    };

    const validationStatus: React.ComponentProps<typeof Message>["validation"] =
        !customers.length
            ? undefined
            : customers.length > 100
            ? "error"
            : "success";
    const validationMessageStr =
        customers.length > 100
            ? `${customers.length} requesters exceeds the max of 100`
            : `${customers.length}`;

    return (
        <Dropdown
            inputValue={inputValue}
            selectedItems={customers}
            onSelect={(items) => setCustomers(items)}
            downshiftProps={{
                defaultHighlightedIndex: 0,
                itemToString: (item: CustomerSearchResult) =>
                    item?.customerId ?? "",
            }}
            onInputValueChange={(value) => setInputValue(value)}
        >
            <Field>
                <StyledFlexRow>
                    <Label>Requesters*</Label>
                    <Button
                        isBasic
                        disabled={disabled}
                        size="small"
                        onClick={() => setIsImportModalVisible(true)}
                    >
                        Import Emails
                    </Button>
                    {isImportModalVisible && (
                        <CustomerImportModal
                            closeModal={() => setIsImportModalVisible(false)}
                            setCustomers={setCustomers}
                        />
                    )}
                </StyledFlexRow>
                <Multiselect
                    disabled={disabled}
                    isCompact
                    placeholder="search name or email"
                    renderItem={({
                        value,
                        removeValue,
                    }: {
                        value?: CustomerSearchResult;
                        removeValue: () => void;
                    }) => (
                        <Tag>
                            <span>{value?.name ?? ""}</span>
                            <Tag.Close onClick={() => removeValue()} />
                        </Tag>
                    )}
                />
                {validationStatus === "error" && (
                    <Message
                        validation={validationStatus}
                        validationLabel={validationMessageStr}
                    >
                        {validationMessageStr}
                    </Message>
                )}
            </Field>
            <Menu isCompact>{menuRenderOptions()}</Menu>
        </Dropdown>
    );
};
