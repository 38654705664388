import { Button } from "@zendeskgarden/react-buttons";
import {
    Body,
    Close,
    Footer,
    FooterItem,
    Header,
    Modal,
} from "@zendeskgarden/react-modals";
import { Paragraph, UnorderedList } from "@zendeskgarden/react-typography";
import pluralize from "pluralize";
import { mapStatusApiToDisplay } from "./CreateTicketsButton";
import { TicketStatus } from "./hooks/useCreateTicketsMutation";

type CreateTicketsConfirmationProps = {
    isOpen: boolean;
    onClose: () => void;
    confirmationHandler: () => void;
    ticketStatus: TicketStatus;
    numRequesters: number;
    assignToAgent: boolean;
};

export const CreateTicketsConfirmation: React.FC<
    CreateTicketsConfirmationProps
> = ({
    isOpen,
    onClose,
    confirmationHandler,
    ticketStatus,
    numRequesters,
    assignToAgent,
}) => {
    if (!isOpen) return null;
    return (
        <Modal onClose={onClose}>
            <Header tag="h2">Confirm ticket creation</Header>
            <Body>
                <Paragraph>Are you sure you want to create:</Paragraph>
                <UnorderedList style={{ marginTop: "0.5em" }}>
                    <UnorderedList.Item>
                        {pluralize("ticket", numRequesters, true)}
                    </UnorderedList.Item>
                    <UnorderedList.Item>
                        with an initial status of &quot;
                        {mapStatusApiToDisplay[ticketStatus].text}&quot;
                    </UnorderedList.Item>
                    <UnorderedList.Item>
                        and all tickets{" "}
                        {assignToAgent
                            ? "assigned to current agent"
                            : "unassigned"}
                    </UnorderedList.Item>
                </UnorderedList>
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={onClose} isBasic>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    <Button
                        isPrimary
                        onClick={() => {
                            confirmationHandler();
                            onClose();
                        }}
                    >
                        Create tickets
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
};
