import { ContainerHeightResize } from "@components/container-height-resize";
import { CustomerBrandDropdown } from "@components/customer-brand-dropdown";
import { Spinner } from "@components/spinner";
import { InfoDisplayOptions } from "@components/tour-details-view";
import { useBrandedCustomer } from "@hooks/useBrandedCustomer";
import { useTicket } from "@hooks/useTicket";
import { useTourDetailsData } from "@hooks/useTourDetailsData";
import { ZafAppLocation } from "@localtypes/zafTypes";
import { Anchor } from "@zendeskgarden/react-buttons";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import { Alert, Title } from "@zendeskgarden/react-notifications";
import { ReactComponent as ChevronLeftIcon } from "@zendeskgarden/svg-icons/src/12/chevron-left-fill.svg";
import { useEffect, useState } from "react";
import styled from "styled-components";
import useLocalStorageState from "use-local-storage-state";
import { ConfigView } from "./ConfigView";
import { TravelersDetailsView } from "./TravelersDetailsView";

const HeaderNavGrid = styled(Grid)(({ theme }) => ({
    borderBottom: `${theme.borders.sm} ${theme.palette.grey[300]}`,
    marginBottom: theme.space.md,
    paddingBottom: theme.space.md,
}));

export type TicketManagerViewProps = {
    location: ZafAppLocation;
};

export const TicketManagerView: React.FC<TicketManagerViewProps> = ({
    location,
}) => {
    const [travelersTripIds, setTravelersTripIds] = useState<string[]>([]);
    const [displayOptions, setDisplayOptions] = useLocalStorageState<
        InfoDisplayOptions[]
    >("ticketManager.displayOptions", {
        defaultValue: [],
        // Don't sync displayOption changes with existing tabs
        storageSync: false,
    });

    const {
        fieldData,
        loading: ticketLoading,
        businessId: ticketBusinessId,
    } = useTicket(location);

    const {
        customerId: brandedCustomerId,
        selectedCustomerBrand,
        inUserSidebar,
        hasMultipleIds,
        businessId: brandedBusinessId,
        setSelectedCustomerBrand,
    } = useBrandedCustomer(location);

    const [requesterTripId, setRequesterTripId] = useState<string>();
    const [showConfig, setShowConfig] = useState(true);
    const customerId = inUserSidebar ? brandedCustomerId : fieldData.customerId;
    const businessId = inUserSidebar ? brandedBusinessId : ticketBusinessId;

    const {
        customerTrips,
        queryDepartureWithBookedTripsFunction,
        departureWithBookedTripsQueryLoading,
        selectTrips,
        selectTripDepartureId,
        loading,
        displayedTrips,
    } = useTourDetailsData({
        customerId,
        businessId,
        businessCode: selectedCustomerBrand,
        requesterTripId,
    });

    // Set the tripID to the tripID assigned to the ticket
    useEffect(() => {
        if (fieldData?.tripId) {
            setRequesterTripId(fieldData.tripId);
        }
    }, [fieldData.tripId, setRequesterTripId]);

    // Set the requester as the initially selected traveler
    useEffect(() => {
        setTravelersTripIds([requesterTripId ?? ""]);
    }, [requesterTripId]);

    if (ticketLoading || loading) return <Spinner />;

    if (!inUserSidebar && !fieldData.customerId)
        return (
            <Alert type="warning">
                <Title>No customer assigned</Title>
                Submit the ticket to see details. It can take up to 15 seconds
                for details to load. If this error persists, please refresh.
            </Alert>
        );

    if (!customerId)
        return (
            <Alert type="warning">
                <Title>Missing customer ID</Title>
                Current customer is missing a Customer ID. Unable to display any
                trips.
            </Alert>
        );

    if (showConfig)
        return (
            <ContainerHeightResize
                // When config is shown, set minHeight large enough to make the expanded
                // dropdown menus fully visible in the iframe
                minHeight={
                    brandedCustomerId && requesterTripId && showConfig
                        ? 400
                        : 50
                }
            >
                <ConfigView
                    customerId={customerId ?? ""}
                    requesterTripId={requesterTripId}
                    setRequesterTripId={setRequesterTripId}
                    travelersTripIds={travelersTripIds}
                    setTravelersTripIds={setTravelersTripIds}
                    displayOptions={displayOptions}
                    setDisplayOptions={setDisplayOptions}
                    onSubmitConfig={async () => {
                        queryDepartureWithBookedTripsFunction(
                            selectTripDepartureId,
                        );
                        setShowConfig(false);
                    }}
                    loadingTrips={departureWithBookedTripsQueryLoading}
                    selectedCustomerBrand={selectedCustomerBrand}
                    inUserSidebar={inUserSidebar}
                    hasMultipleIds={hasMultipleIds}
                    setSelectedCustomerBrand={setSelectedCustomerBrand}
                    location={location}
                    customerTrips={customerTrips}
                    tourTravelers={selectTrips}
                />
            </ContainerHeightResize>
        );

    return (
        <ContainerHeightResize
            // When config is shown, set minHeight large enough to make the expanded
            // dropdown menus fully visible in the iframe
            minHeight={
                brandedCustomerId && requesterTripId && showConfig ? 400 : 50
            }
        >
            <HeaderNavGrid>
                {inUserSidebar && hasMultipleIds && (
                    <Row style={{ marginTop: 12 }}>
                        <Col>
                            <CustomerBrandDropdown
                                selectedCustomerBrand={selectedCustomerBrand}
                                setSelectedCustomerBrand={
                                    setSelectedCustomerBrand
                                }
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        <Anchor onClick={() => setShowConfig(true)}>
                            <ChevronLeftIcon style={{ marginRight: 12 }} />
                            Back to select
                        </Anchor>
                    </Col>
                </Row>
            </HeaderNavGrid>
            <TravelersDetailsView
                requesterTripId={requesterTripId ?? ""}
                travelersTripIds={travelersTripIds}
                displayOptions={displayOptions}
                businessId={businessId ?? ""}
                trips={displayedTrips}
            />
        </ContainerHeightResize>
    );
};
