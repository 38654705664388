import { useContainerHeightResize } from "@hooks/useContainerHeightResize";

export type ContainerHeightResizeProps = {
    children: React.ReactNode;
    minHeight?: number;
};

/**
 * Wrapper component which updates the height of the app's ZAF iframe to match
 * the height of the child component
 *
 * @param children
 * @returns
 */
export const ContainerHeightResize: React.FC<ContainerHeightResizeProps> = ({
    children,
    minHeight = 100,
}) => {
    const containerRef = useContainerHeightResize({ minHeight });
    return (
        <div ref={containerRef} style={{ margin: 0, padding: 0 }}>
            {children}
        </div>
    );
};
