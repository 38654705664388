import { CustomerBrandDropdown } from "@components/customer-brand-dropdown";
import { Spinner } from "@components/spinner";
import { useBrandedCustomer } from "@hooks/useBrandedCustomer";
import { useTicket } from "@hooks/useTicket";
import { ZafAppLocation } from "@localtypes/zafTypes";
import { Button } from "@zendeskgarden/react-buttons";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import { Alert, Title } from "@zendeskgarden/react-notifications";
import { ReactComponent as NewWindowIcon } from "@zendeskgarden/svg-icons/src/12/new-window-stroke.svg";
import { RequesterView } from "./RequesterView";
import { useZafCustomerQuery } from "@graphql/zaf-graphql-client";
import { ZendeskCustomerFragment } from "@graphql/fragments/generated/CustomerFragment";

export type RequesterSidebarProps = {
    location: ZafAppLocation;
};

export const RequesterSidebar: React.FC<RequesterSidebarProps> = ({
    location,
}) => {
    const {
        customerId: brandedCustomerId,
        customerSalesforceId,
        selectedCustomerBrand,
        businessId: brandedBusinessId,
        inUserSidebar,
        hasMultipleIds,
        setSelectedCustomerBrand,
    } = useBrandedCustomer(location);

    const {
        fieldData,
        loading: ticketLoading,
        businessId: ticketBusinessId,
    } = useTicket(location);

    const customerId = inUserSidebar ? brandedCustomerId : fieldData.customerId;
    const businessId = inUserSidebar ? brandedBusinessId : ticketBusinessId;

    const { data: customerData, isLoading: customerQueryLoading } =
        useZafCustomerQuery(fieldData.customerId || customerId);

    const customer: null | ZendeskCustomerFragment = customerData ?? null;

    const handleOpenCustomer = (customerSalesforceId: string) => {
        const url = new URL(
            `/${customerSalesforceId}`,
            import.meta.env.VITE_SALES_FORCE_URL,
        );

        window.open(url.href, "_blank", "noopener,noreferrer");
    };

    if (customerQueryLoading || ticketLoading) return <Spinner />;

    if (!inUserSidebar && !fieldData.customerId)
        return (
            <Alert type="warning">
                <Title>No customer assigned</Title>
                Submit the ticket to see details. It can take up to 15 seconds
                for details to load. If this error persists, please refresh.
            </Alert>
        );

    if (!customerId || !customerData || !businessId)
        return (
            <Alert type="warning">
                <Title>Missing customer ID</Title>
                Current customer is missing a Customer ID. Unable to display any
                trips.
            </Alert>
        );

    return (
        <Grid>
            {inUserSidebar && hasMultipleIds && (
                <Row style={{ marginTop: 12 }}>
                    <Col>
                        <CustomerBrandDropdown
                            selectedCustomerBrand={selectedCustomerBrand}
                            setSelectedCustomerBrand={setSelectedCustomerBrand}
                        />
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Button
                        disabled={!customerSalesforceId}
                        onClick={() =>
                            customerSalesforceId &&
                            handleOpenCustomer(customerSalesforceId)
                        }
                        size="small"
                    >
                        Open in Salesforce
                        <Button.EndIcon>
                            <NewWindowIcon />
                        </Button.EndIcon>
                    </Button>
                </Col>
            </Row>
            <RequesterView customer={customer} />
        </Grid>
    );
};
