import { useZafClient } from "@hooks/useZafClient";
import { CustomerSearchResult } from "./useCustomerAutocomplete";
import {
    jobStatusResponseSchema,
    getJobStatusQuerySettled,
    isJobStatusResultSuccess,
    isJobStatusResultError,
} from "./getJobStatusQuerySettled";
import { getTicketsQuery } from "./getTicketsQuery";
import { z } from "zod";
import { useMutation } from "@tanstack/react-query";

// Define schemas for the API data, so we can later validate our calls in tests
const ticketStatusSchema = z.union([
    z.literal("new"),
    z.literal("open"),
    z.literal("pending"),
    z.literal("hold"),
    z.literal("solved"),
]);
export type TicketStatus = z.infer<typeof ticketStatusSchema>;

export const ticketContentSchema = z.object({
    subject: z.string(),
    comment: z.object({
        html_body: z.string(),
    }),
    requester_id: z.number(),
    assignee_id: z.number().nullable(),
    status: ticketStatusSchema,
    tags: z.array(z.string()),
    brand_id: z.number().nullable(),
});
export type TicketContent = z.infer<typeof ticketContentSchema>;

export type CreateTicketsMutationResult = {
    ticketIds: number[];
    messages: string[];
    missingRequesters: CustomerSearchResult[];
};

export const useCreateTicketsMutation = () => {
    const zafClient = useZafClient();

    const createTicketsMutation = async ({
        tickets,
        requesters,
    }: {
        tickets: TicketContent[];
        requesters: CustomerSearchResult[];
    }): Promise<CreateTicketsMutationResult> => {
        if (!zafClient) throw new Error("Missing ZafClient");

        // `create_many` API only supports up to 100 tickets
        if (tickets.length > 100) {
            throw new Error(
                "Cannot create more than 100 tickets in a single API call",
            );
        }

        if (tickets.length < 1) {
            throw new Error("Must specify at least 1 ticket requester.");
        }

        const response = await zafClient.request({
            url: "/api/v2/tickets/create_many.json",
            type: "POST",
            contentType: "application/json",
            data: JSON.stringify({
                tickets,
            }),
        });
        const jobStatusParse = jobStatusResponseSchema.parse(response);
        const jobStatus = jobStatusParse.job_status;

        // poll the jobStatus URL until job is completed
        const jobStatusSettledResult = await getJobStatusQuerySettled(
            jobStatus.url,
            zafClient,
        );

        if (jobStatusSettledResult.status !== "settled") {
            throw new Error(
                "Zendesk error: Job status never settled: " +
                    jobStatusSettledResult.error,
            );
        }

        const jobStatusData = jobStatusSettledResult.jobStatus;
        if (!jobStatusData.results || !Array.isArray(jobStatusData?.results)) {
            // API should always return an array of results
            throw new Error("Zendesk error: Missing jobStatus results");
        }

        // Get all the successfully created tickets
        const ticketResults = jobStatusData.results.filter(
            isJobStatusResultSuccess,
        );
        const ticketResultsIds = ticketResults
            .map((result) => result.id)
            .sort((left, right) => left - right);

        // Each failed ticket will have separate error entry
        const errorResults = jobStatusData.results.filter(
            isJobStatusResultError,
        );
        const errorResultsMessages = [
            // Only return 1 of each type of error message
            ...new Set(errorResults.map((result) => result.details)),
        ];

        // Determine if tickets were not created for any email addresses.
        // Since the API result obj does not return the customer/email address,
        // we need to query for the requester/email of each successful ticket
        const createdTickets = await getTicketsQuery(
            ticketResultsIds,
            zafClient,
        );

        // Determine which customers do not have a successfully created ticket
        const missingRequesters = requesters?.reduce<CustomerSearchResult[]>(
            (accum, currRequester) =>
                createdTickets.some(
                    (successCustomer) =>
                        successCustomer.requester_id ===
                        currRequester.customerId,
                )
                    ? accum
                    : [...accum, currRequester],
            [],
        );

        return {
            ticketIds: ticketResultsIds,
            messages: errorResultsMessages,
            missingRequesters,
        };
    };

    return useMutation({
        mutationFn: createTicketsMutation,
    });
};
