import { useZafGraphqlClient } from "@hooks/useZafGraphqlClient";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {
    BookingTripByLegacyIdDocument,
    BookingTripByLegacyIdQuery,
} from "@graphql/queries/generated/BookingTripByLegacyIdQuery";
import { BookingTrip } from "@localtypes/generated-gql";

export const useZafBookingTripQuery = (
    id: undefined | string,
): UseQueryResult<null | BookingTrip> => {
    const zafGraphqlClient = useZafGraphqlClient();

    const queryFn = async (): Promise<
        null | BookingTripByLegacyIdQuery["bookingTripByLegacyId"]
    > => {
        const response =
            await zafGraphqlClient?.execute<BookingTripByLegacyIdQuery>(
                BookingTripByLegacyIdDocument,
                { id },
            );
        return response?.bookingTripByLegacyId ?? null;
    };

    return useQuery({
        queryKey: ["useZafBookingTripQuery"],
        queryFn,
        enabled: !!(id && zafGraphqlClient),
    });
};
