import { CustomerBrandDropdown } from "@components/customer-brand-dropdown/CustomerBrandDropdown";
import { CustomerTripsDropdown } from "@components/customer-trips-dropdown";
import { Spinner } from "@components/spinner";
import {
    LinkTicketButtonState,
    TourDetailsView,
} from "@components/tour-details-view";
import { TravelersDropdown } from "@components/travelers-dropdown";
import { useBrandedCustomer } from "@hooks/useBrandedCustomer";
import { useTicket } from "@hooks/useTicket";
import { useTourDetailsData } from "@hooks/useTourDetailsData";
import { ZafAppLocation } from "@localtypes/zafTypes";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import { Alert, Title } from "@zendeskgarden/react-notifications";
import { useEffect, useState } from "react";

export type TourSidebarProps = {
    location: ZafAppLocation;
};

export const TourSidebar: React.FC<TourSidebarProps> = ({ location }) => {
    const {
        customerId: brandedCustomerId,
        selectedCustomerBrand,
        inUserSidebar,
        hasMultipleIds,
        businessId: brandedBusinessId,
        setSelectedCustomerBrand,
    } = useBrandedCustomer(location);

    const {
        fieldData,
        isParentTicket,
        loading,
        businessId: ticketBusinessId,
    } = useTicket(location);
    const [requesterTripId, setRequesterTripId] = useState<string | undefined>(
        fieldData.tripId,
    );

    const [selectedTravelerTripId, setSelectedTravelerTripId] =
        useState<string>();

    const businessId = inUserSidebar ? brandedBusinessId : ticketBusinessId;

    let linkTicketButtonState: LinkTicketButtonState = "Hidden";

    const isLinkTicketButtonEnabled = fieldData.tripId !== requesterTripId;
    const customerId = inUserSidebar ? brandedCustomerId : fieldData.customerId;

    if (!isParentTicket || location === "user_sidebar") {
        linkTicketButtonState = "Hidden";
    } else if (isLinkTicketButtonEnabled && !loading) {
        linkTicketButtonState = "Enabled";
    } else {
        linkTicketButtonState = "Disabled";
    }

    const {
        customerTrips,
        loading: tourDetailsDataLoading,
        selectTrips,
        customerTrip,
    } = useTourDetailsData({
        customerId,
        businessId,
        businessCode: selectedCustomerBrand,
        requesterTripId,
        selectedTravelerTripId,
    });

    useEffect(() => {
        if (fieldData.tripId) {
            setRequesterTripId(fieldData.tripId);
        }
    }, [fieldData.tripId]);

    // Set the initially selected traveler to the requester
    useEffect(() => {
        setSelectedTravelerTripId(requesterTripId);
    }, [requesterTripId]);

    if (tourDetailsDataLoading) {
        return <Spinner />;
    }

    if (!inUserSidebar && !fieldData.customerId)
        return (
            <Alert type="warning">
                <Title>No customer assigned</Title>
                Submit the ticket to see details. It can take up to 15 seconds
                for details to load. If this error persists, please refresh.
            </Alert>
        );

    if (!customerId || (isParentTicket && !businessId)) {
        return (
            <Alert type="warning">
                <Title>Missing customer ID</Title>
                Current customer is missing a Customer ID. Unable to display any
                trips.
            </Alert>
        );
    }

    return (
        <>
            <Grid>
                {inUserSidebar && hasMultipleIds && isParentTicket && (
                    <Row style={{ marginTop: 12 }}>
                        <Col>
                            <CustomerBrandDropdown
                                selectedCustomerBrand={selectedCustomerBrand}
                                setSelectedCustomerBrand={
                                    setSelectedCustomerBrand
                                }
                            />
                        </Col>
                    </Row>
                )}
                <Row
                    data-testid="customerTripsDropdown"
                    style={{ marginTop: 12 }}
                >
                    <Col>
                        <CustomerTripsDropdown
                            customerId={customerId}
                            selectedTripId={requesterTripId}
                            setSelectedTripId={setRequesterTripId}
                            isLabelVisible={true}
                            labelText="Requester tour"
                            inUserSidebar={inUserSidebar}
                            location={location}
                            trips={customerTrips}
                        />
                    </Col>
                </Row>
                <Row
                    data-testid="customerTripsDropdown"
                    style={{ marginTop: 20 }}
                >
                    <Col>
                        {requesterTripId && (
                            <TravelersDropdown
                                requesterTripId={requesterTripId ?? ""}
                                selectedTravelerTripId={selectedTravelerTripId}
                                setSelectedTravelerTripId={
                                    setSelectedTravelerTripId
                                }
                                businessId={businessId ?? ""}
                                trips={selectTrips}
                            />
                        )}
                    </Col>
                </Row>
            </Grid>
            <TourDetailsView
                trip={customerTrip}
                requesterTripId={requesterTripId ?? ""}
                showCopyTourToClipboardButton={true}
                businessId={businessId ?? ""}
                linkTicketButtonState={linkTicketButtonState}
            />
        </>
    );
};
