import { ZafClient } from "@localtypes/zafTypes";
import { z } from "zod";

// Ticket creation is successful
const jobStatusResultSuccessSchema = z.object({
    account_id: z.number(),
    index: z.number(),
    id: z.number(), // ticket number
});
type JobStatusResultSuccess = z.infer<typeof jobStatusResultSuccessSchema>;
export const isJobStatusResultSuccess = (
    status: JobStatusResultSuccess | JobStatusResultError,
): status is JobStatusResultSuccess => "account_id" in status;

// Job status completes but has errors for a particular result
const jobStatusResultErrorSchema = z.object({
    error: z.string(), // single-word error code
    details: z.string(), // error description phrase
    id: z.number(),
});
type JobStatusResultError = z.infer<typeof jobStatusResultErrorSchema>;
export const isJobStatusResultError = (
    status: JobStatusResultSuccess | JobStatusResultError,
): status is JobStatusResultError => "error" in status;

export const jobStatusResponseSchema = z.object({
    job_status: z.object({
        url: z.string(),
        total: z.number().nullable(),
        progress: z.number().nullable(),
        status: z.enum(["queued", "working", "failed", "completed", "killed"]),
        message: z.string().nullable(),
        results: z
            .array(
                z.union([
                    jobStatusResultSuccessSchema,
                    jobStatusResultErrorSchema,
                    // Update or delete jobs will return other object types
                ]),
            )
            .nullable(),
    }),
});

export type JobStatus = z.infer<
    typeof jobStatusResponseSchema.shape.job_status
>;

type JobStatusSettledReturn =
    | {
          status: "error";
          error: string;
      }
    | {
          status: "settled";
          jobStatus: JobStatus;
      };

const POLL_INTERVAL_MS = 100;
const MAX_RETRIES = Math.floor((60 * 1000) / POLL_INTERVAL_MS);

// re-query the URL until the job status has settled.
export const getJobStatusQuerySettled = async (
    jobStatusUrl: string,
    zafClient: ZafClient,
): Promise<JobStatusSettledReturn> => {
    let retryNum = 0;

    // Recursively poll the jobStatus URL
    const checkJobStatus = async (
        resolve: (value: JobStatus | PromiseLike<JobStatus>) => void,
        reject: (message: string) => void,
    ): Promise<void> => {
        // Call the jobStatus API
        const response = await zafClient.request(jobStatusUrl);
        const jobStatusParse = jobStatusResponseSchema.parse(response);

        const jobStatus = jobStatusParse.job_status;

        if (["queued", "working"].includes(jobStatus.status)) {
            if (retryNum > MAX_RETRIES) {
                return reject("Job timeout. Exceeded max number of retries.");
            }
            // Check the job status again
            retryNum++;
            setTimeout(checkJobStatus, POLL_INTERVAL_MS, resolve, reject);
            return;
        }

        if (jobStatus.status === "failed") {
            return reject(jobStatus?.message ?? "Job failed");
        }
        if (jobStatus.status === "killed") {
            return reject(jobStatus?.message ?? "Job was killed");
        }
        // job was completed successfully
        return resolve(jobStatus);
    };

    try {
        retryNum = 0;
        const jobStatus = await new Promise<JobStatus>(checkJobStatus);
        return {
            status: "settled",
            jobStatus,
        };
    } catch (err) {
        const errString =
            err instanceof Error
                ? err.message
                : typeof err === "string"
                ? err
                : "Unknown error";
        return { status: "error", error: errString };
    }
};
