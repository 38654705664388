import { useZafGraphqlClient } from "@hooks/useZafGraphqlClient";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {
    CustomerWithBookingTripsDocument,
    CustomerWithBookingTripsQuery,
} from "@graphql/queries/generated/CustomerWithBookingTripsQuery";
import { BookingTrip, BusinessCode, Scalars } from "@localtypes/generated-gql";

export type CustomerWithBookingTrips = {
    _id: Scalars["ObjectId"];
    bookingTrips: BookingTrip[];
};

export const useZafCustomerWithBookingTripsQuery = (
    id: undefined | string,
    businessCode: undefined | BusinessCode,
): UseQueryResult<null | CustomerWithBookingTrips> => {
    const zafGraphqlClient = useZafGraphqlClient();

    const queryFn = async (): Promise<
        null | CustomerWithBookingTripsQuery["customer"]
    > => {
        const response =
            await zafGraphqlClient?.execute<CustomerWithBookingTripsQuery>(
                CustomerWithBookingTripsDocument,
                { id, businessCode },
            );
        return response?.customer ?? null;
    };

    return useQuery({
        queryKey: ["useZafCustomerWithBookingTripsQuery"],
        queryFn,
        enabled: !!(id && businessCode && zafGraphqlClient),
    });
};
