import dayjs from "dayjs";
import {
    DataCol,
    DataRow,
    LabelCol,
    StyledSectionHeaderCol,
    StyledSectionTopRow,
} from "./StyledGrid";
import { ZendeskAppTrip } from "@localtypes/zendesk-app.types";

export type TransferDetailsProps = {
    trip: ZendeskAppTrip;
};

export const TransferDetails: React.FC<TransferDetailsProps> = ({ trip }) => {
    const transferInfo = [...(trip?.airportTransferFlightInformation ?? [])];
    const sortedTransfers = transferInfo
        .sort((flight1, flight2) =>
            dayjs
                .utc(flight1?.departureDate)
                .diff(dayjs.utc(flight2?.departureDate)),
        )
        .map((flight) => ({
            arrivalGateway: flight?.gatewayTo,
            departureGateway: flight?.gatewayFrom,
            departDate: flight?.departureDate,
            departureTime: flight?.departureTime,
            arrivalDate: flight?.arrivalDate,
            arrivalTime: flight?.arrivalTime,
            pnr: flight?.pnr,
            flightNumber: flight?.flightNumber,
            airline: flight?.airline,
        }));

    const arrivingTransfer = sortedTransfers[0];
    const departingTransfer = sortedTransfers[1];

    if (transferInfo.length < 1) {
        return (
            <>
                <StyledSectionTopRow>
                    <StyledSectionHeaderCol>Transfers</StyledSectionHeaderCol>
                </StyledSectionTopRow>
                <DataRow>
                    <LabelCol>N/A</LabelCol>
                    <DataCol>-</DataCol>
                </DataRow>
            </>
        );
    }

    return (
        <>
            <StyledSectionTopRow>
                <StyledSectionHeaderCol>Transfers</StyledSectionHeaderCol>
            </StyledSectionTopRow>
            <>
                {arrivingTransfer ? (
                    <>
                        <DataRow key={arrivingTransfer.flightNumber}>
                            <LabelCol>
                                <>
                                    {dayjs
                                        .utc(arrivingTransfer.arrivalDate)
                                        .format("MMM D, YYYY")}{" "}
                                    at{" "}
                                    {dayjs
                                        .utc(arrivingTransfer.arrivalTime)
                                        .format("HH:mm")}
                                </>
                            </LabelCol>
                            <DataCol>
                                {arrivingTransfer.arrivalGateway?.toUpperCase()}
                                <br />
                                {arrivingTransfer.airline?.toUpperCase()}{" "}
                                {arrivingTransfer.flightNumber}
                            </DataCol>
                        </DataRow>
                        <DataRow key={arrivingTransfer.pnr}>
                            <LabelCol>PNR</LabelCol>
                            <DataCol>
                                {arrivingTransfer.pnr?.toUpperCase()}
                            </DataCol>
                        </DataRow>
                    </>
                ) : null}
                {departingTransfer ? (
                    <>
                        <DataRow key={departingTransfer.flightNumber}>
                            <LabelCol>
                                {dayjs
                                    .utc(departingTransfer.departDate)
                                    .format("MMM D, YYYY")}{" "}
                                at{" "}
                                {dayjs
                                    .utc(departingTransfer.departureTime)
                                    .format("HH:mm")}
                            </LabelCol>
                            <DataCol>
                                {departingTransfer.departureGateway?.toUpperCase()}
                                <br />
                                {departingTransfer.airline?.toUpperCase()}{" "}
                                {departingTransfer.flightNumber}
                            </DataCol>
                        </DataRow>
                        <DataRow key={departingTransfer.pnr}>
                            <LabelCol>PNR</LabelCol>
                            <DataCol>
                                {departingTransfer.pnr?.toUpperCase()}
                            </DataCol>
                        </DataRow>
                    </>
                ) : null}
            </>
        </>
    );
};
