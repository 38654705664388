import {
    Button,
    ChevronButton,
    SplitButton,
} from "@zendeskgarden/react-buttons";
import {
    Dropdown,
    Item,
    MediaBody,
    MediaFigure,
    Menu,
    Trigger,
} from "@zendeskgarden/react-dropdowns";
import { Dots } from "@zendeskgarden/react-loaders";
import { DEFAULT_THEME } from "@zendeskgarden/react-theming";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import { useState } from "react";
import styled from "styled-components";
import { CreateTicketsConfirmation } from "./CreateTicketsConfirmation";
import { ReactComponent as SquareIcon } from "./assets/squareIcon.svg";
import { TicketStatus } from "./hooks/useCreateTicketsMutation";

// Map the Zendesk API "status" to the UI/display-able status string.
// The Zendesk API defines statuses as lowercase -- and "hold" rather than "on-hold".
export const mapStatusApiToDisplay: Record<
    TicketStatus,
    { text: string; color: string; tooltip: string }
> = {
    new: {
        text: "New",
        color: DEFAULT_THEME.palette.yellow[400],
        tooltip: "Ticket is awaiting assignment to an agent",
    },
    open: {
        text: "Open",
        color: DEFAULT_THEME.palette.crimson[600],
        tooltip: "Staff is working on the ticket",
    },
    pending: {
        text: "Pending",
        color: DEFAULT_THEME.palette.azure[400],
        tooltip: "Staff is waiting for the requester to reply",
    },
    hold: {
        text: "On-hold",
        color: DEFAULT_THEME.palette.grey[800],
        tooltip: "Staff is waiting for a third party",
    },
    solved: {
        text: "Solved",
        color: DEFAULT_THEME.palette.grey[500],
        tooltip: "The ticket has been solved",
    },
} as const;

const StyledBoldSpan = styled.span(({ theme }) => ({
    fontWeight: theme.fontWeights["bold"],
    marginLeft: "6px",
}));

const StyledMediaBody = styled(MediaBody)(({ theme }) => ({
    fontWeight: theme.fontWeights["semibold"],
}));

type CreateTicketsButtonProps = {
    ticketStatus: TicketStatus;
    setTicketStatus: React.Dispatch<React.SetStateAction<TicketStatus>>;
    onClickHandler: () => void;
    disabled: boolean;
    numRequesters: number;
    assignToAgent: boolean;
    isLoading: boolean;
};
export const CreateTicketsButton: React.FC<CreateTicketsButtonProps> = ({
    ticketStatus,
    setTicketStatus,
    onClickHandler,
    disabled,
    numRequesters,
    assignToAgent,
    isLoading,
}) => {
    const [rotated, setRotated] = useState<boolean>(false);
    const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);

    // Cannot set status of "new" if ticket is assigned to an agent
    const defaultStatus: TicketStatus = assignToAgent ? "open" : "new";

    return (
        <>
            <SplitButton>
                <Button
                    disabled={disabled || isLoading}
                    isPrimary
                    onClick={() => {
                        setTicketStatus(defaultStatus);
                        setConfirmationOpen(true);
                    }}
                >
                    {isLoading && (
                        <Button.StartIcon>
                            <Dots size={32} delayMS={0} />
                        </Button.StartIcon>
                    )}
                    Submit as{" "}
                    <StyledBoldSpan>
                        {mapStatusApiToDisplay[defaultStatus].text}
                    </StyledBoldSpan>
                </Button>
                <Dropdown
                    onStateChange={(options) => setRotated(!!options.isOpen)}
                    onSelect={(item) => {
                        setTicketStatus(item);
                        setConfirmationOpen(true);
                    }}
                >
                    <Trigger>
                        <ChevronButton
                            disabled={disabled || isLoading}
                            isPrimary
                            aria-label="ticket status"
                            isRotated={rotated}
                        />
                    </Trigger>
                    <Menu placement="bottom-end">
                        {Object.entries(mapStatusApiToDisplay)
                            // feature flag the "hold" status
                            .filter(
                                ([status]) =>
                                    status !== "hold" ||
                                    import.meta.env
                                        .VITE_ONHOLD_STATUS_ENABLED === "true",
                            )
                            .map(([status, displayStatus]) => (
                                <Tooltip
                                    key={status}
                                    size="medium"
                                    content={displayStatus.tooltip}
                                    placement="start"
                                >
                                    <Item
                                        value={status}
                                        disabled={
                                            assignToAgent && status === "new"
                                        }
                                    >
                                        <MediaFigure>
                                            <SquareIcon
                                                style={{
                                                    fill: displayStatus.color,
                                                }}
                                            />
                                        </MediaFigure>
                                        <StyledMediaBody>
                                            {displayStatus.text}
                                        </StyledMediaBody>
                                    </Item>
                                </Tooltip>
                            ))}
                    </Menu>
                </Dropdown>
            </SplitButton>
            <CreateTicketsConfirmation
                isOpen={confirmationOpen}
                onClose={() => setConfirmationOpen(false)}
                ticketStatus={ticketStatus}
                assignToAgent={assignToAgent}
                numRequesters={numRequesters}
                confirmationHandler={onClickHandler}
            />
        </>
    );
};
