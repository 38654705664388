import { CustomerTripsDropdown } from "@components/customer-trips-dropdown";
import { Spinner } from "@components/spinner";
import { useResize } from "@hooks/useResize";
import { useTicket } from "@hooks/useTicket";
import { useZafClient } from "@hooks/useZafClient";
import { Button, IconButton } from "@zendeskgarden/react-buttons";
import { Field, Input, Label } from "@zendeskgarden/react-forms";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import { Alert, Title } from "@zendeskgarden/react-notifications";
import { MD } from "@zendeskgarden/react-typography";
import { ReactComponent as CloseIcon } from "@zendeskgarden/svg-icons/src/12/x-stroke.svg";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useErrorToast } from "./useErrorToast";
import dayjs from "dayjs";
import {
    useZafBookingTripQuery,
    useZafCustomerWithBookingTripsQuery,
} from "@graphql/zaf-graphql-client";
import { ZendeskAppTripMapper } from "@hooks/ZendeskAppTripMapper";

const StyledContainer = styled.div({
    height: "100vh",
    display: "flex",
    flexDirection: "column",
});

const HeaderGrid = styled(Grid)(({ theme }) => ({
    borderBottom: `${theme.borders.sm} ${theme.palette.grey[200]}`,
    flex: "none",
}));

const StyledRow = styled(Row)({
    marginBottom: "20px",
});

const DEFAULT_HEIGHT = 302;
const DEFAULT_WIDTH = 400;

export const InvoiceLinkView: React.FC = () => {
    const zafClient = useZafClient();

    useResize({ width: `${DEFAULT_WIDTH}px`, height: `${DEFAULT_HEIGHT}px` });

    const {
        fieldData,
        loading: ticketLoading,
        businessId,
        businessCode,
    } = useTicket("ticket_editor");

    const [selectedTripId, setSelectedTripId] = useState<string | undefined>(
        fieldData.tripId,
    );
    const [linkText, setLinkText] = useState("");

    const { data: trip, isLoading: tripLoading } = useZafBookingTripQuery(
        fieldData.tripId,
    );

    useEffect(() => {
        const departureName = trip?.departure?.tourContent?.name;
        if (departureName) {
            setLinkText(
                `${trip?.departure?.tourContent?.name}_${dayjs().format(
                    "MM.DD.YYYY",
                )}`,
            );
        }
    }, [trip]);

    const errorToast = useErrorToast();

    const closeView = () => {
        zafClient?.invoke("app.close");
    };

    const insertInvoiceDocumentLink = async () => {
        const bookingId = trip?.booking?._id;

        if (!bookingId) {
            errorToast("Can't add invoice - Booking ID is not defined.");
        }

        try {
            const attachmentUrl = `${
                import.meta.env.VITE_WOJO_API_URL
            }/order/${bookingId}/reports/invoice`;

            await zafClient?.invoke(
                "ticket.editor.insert",
                `<a href=${attachmentUrl}>${linkText}</a>`,
            );

            closeView();
        } catch (err) {
            errorToast(err instanceof Error ? err.message : "Unknown error");
        }
    };

    const {
        data: customerWithBookingTripsQueryResult,
        isLoading: customerWithBookingTripsQueryLoading,
    } = useZafCustomerWithBookingTripsQuery(fieldData.customerId, businessCode);
    const customerTrips =
        customerWithBookingTripsQueryResult?.bookingTrips?.map(
            ZendeskAppTripMapper.map,
        ) ?? [];

    if (ticketLoading || tripLoading || customerWithBookingTripsQueryLoading)
        return <Spinner />;

    return (
        <StyledContainer>
            <HeaderGrid>
                <Row
                    style={{
                        padding: "20px 0 20px 8px",
                    }}
                >
                    <Col>
                        <MD tag="h2" isBold>
                            Add invoice
                        </MD>
                    </Col>
                    <Col>
                        <IconButton
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                            }}
                            onClick={closeView}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Col>
                </Row>
            </HeaderGrid>
            {businessId !== import.meta.env.VITE_GAT_BUSINESS_ID ? (
                <Alert type="warning">
                    <Title>Not supported</Title>
                    The invoice link app is only available for Go Ahead
                    customers
                </Alert>
            ) : !fieldData.customerId ? (
                <Alert type="warning">
                    <Title>No trip assigned</Title>
                    Submit the ticket to see details. It can take up to 15
                    seconds for details to load. If this error persists, please
                    refresh.
                </Alert>
            ) : (
                <>
                    <Grid
                        style={{
                            margin: "0px",
                            padding: "20px 20px 0",
                            flex: 1,
                        }}
                    >
                        <StyledRow>
                            <Col>
                                <CustomerTripsDropdown
                                    customerId={fieldData.customerId ?? ""}
                                    selectedTripId={selectedTripId}
                                    setSelectedTripId={setSelectedTripId}
                                    maxHeight={`${DEFAULT_HEIGHT - 20}px`}
                                    labelText={"Order"}
                                    isLabelVisible={true}
                                    inUserSidebar={false}
                                    location="ticket_editor"
                                    trips={customerTrips}
                                />
                            </Col>
                        </StyledRow>
                        <StyledRow>
                            <Col>
                                <Field>
                                    <Label>Text to display</Label>
                                    <Input
                                        value={linkText}
                                        isCompact
                                        disabled={!trip}
                                        onChange={(evt) =>
                                            setLinkText(evt.target.value)
                                        }
                                    />
                                </Field>
                            </Col>
                        </StyledRow>
                    </Grid>
                    <Grid style={{ padding: "0 20px 20px", flex: "none" }}>
                        <Row justifyContent="end">
                            <Col size={3} textAlign="end">
                                <Button
                                    size="small"
                                    onClick={closeView}
                                    isBasic
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col size={3} textAlign="end">
                                <Button
                                    size="small"
                                    isStretched
                                    isPrimary
                                    disabled={
                                        !fieldData.customerId ||
                                        !trip ||
                                        linkText === ""
                                    }
                                    onClick={insertInvoiceDocumentLink}
                                >
                                    {"Add"}
                                </Button>
                            </Col>
                        </Row>
                    </Grid>
                </>
            )}
        </StyledContainer>
    );
};
