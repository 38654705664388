import { CopyTourToClipboardButton } from "@components/copy-tour-to-clipboard-button/CopyTourToClipboardButton";
import {
    InfoDisplayOptions,
    TourDetailsView,
} from "@components/tour-details-view";
import orderBy from "lodash.orderby";
import React from "react";
import styled from "styled-components";
import { ZendeskAppTrip } from "@localtypes/zendesk-app.types";

export type TravelersDetailsViewProps = {
    requesterTripId: string;
    travelersTripIds: string[];
    trips: ZendeskAppTrip[];
    displayOptions: InfoDisplayOptions[];
    businessId: string;
};

const CopyButtonWrapper = styled.div({
    marginLeft: "10px",
    marginBottom: "30px",
});
const TravelerNumber = styled.div(({ theme }) => ({
    color: theme.palette.grey[500],
    paddingLeft: "10px",
    lineHeight: theme.lineHeights.md,
}));
const TravelerName = styled.div(({ theme }) => ({
    fontSize: theme.fontSizes.xl,
    paddingLeft: "10px",
    marginBottom: theme.space.xxs,
    lineHeight: theme.lineHeights.xl,
}));

const sortTravelersTrips = (
    trips: ZendeskAppTrip[] | undefined,
    travelersTripIds: string[],
    requesterTripId: string,
): ZendeskAppTrip[] => {
    if (!trips) return [];

    const requesterOrderId = trips.find((trip) => trip.id === requesterTripId)
        ?.order?.id;

    // remove the requester's trip, if it wasn't selected
    const travelersTrips = trips.filter((trip) =>
        travelersTripIds.includes(trip.id!),
    );

    return orderBy(
        travelersTrips,
        [
            (trip) => trip.id === requesterTripId,
            (trip) => trip.order?.id === requesterOrderId,
            (trip) => trip.customer?.lastName,
            (trip) => trip.customer?.firstName,
        ],
        ["desc", "desc", "asc", "asc"],
    );
};

export const TravelersDetailsView: React.FC<TravelersDetailsViewProps> = ({
    requesterTripId,
    travelersTripIds,
    trips,
    displayOptions,
    businessId,
}) => {
    const sortedTrips = sortTravelersTrips(
        trips,
        travelersTripIds,
        requesterTripId,
    );

    return (
        <React.Fragment>
            <CopyButtonWrapper>
                <CopyTourToClipboardButton
                    trips={sortedTrips}
                    options={displayOptions}
                />
            </CopyButtonWrapper>
            {sortedTrips.map((trip, idx) => (
                <div key={trip.id} style={{ marginBottom: 32 }}>
                    <TravelerName>
                        {trip.customer?.firstName} {trip.customer?.lastName}
                    </TravelerName>
                    <TravelerNumber>
                        Traveler {idx + 1} of {sortedTrips.length}
                        {trip.id === requesterTripId && " (Requester)"}
                    </TravelerNumber>
                    <TourDetailsView
                        trip={trip}
                        options={displayOptions}
                        showNameOnOrderButton
                        showBottomDivider={idx < sortedTrips.length - 1}
                        requesterTripId={requesterTripId}
                        businessId={businessId}
                        linkTicketButtonState={"Hidden"}
                    />
                </div>
            ))}
        </React.Fragment>
    );
};
