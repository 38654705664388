import { formatDates } from "@components/tour-details-view/formatDates";
import { Button } from "@zendeskgarden/react-buttons";
import copy from "copy-to-clipboard";
import dayjs from "dayjs";
import React from "react";
import { InfoDisplayOptions } from "../tour-details-view/TourDetailsView";
import { ZendeskAppTrip } from "@localtypes/zendesk-app.types";

type CopyToClipboardButtonProps = {
    options?: InfoDisplayOptions[];
    trips: ZendeskAppTrip[];
};

export const getAtlasProdRequestsLink = (salesTourId: string) => {
    return `https://atlas.eftours.com:8108/#/trip/${salesTourId}/systemcode/Phoenix`;
};
export const CopyTourToClipboardButton: React.FC<
    CopyToClipboardButtonProps
> = ({ trips, options }) => {
    const copyToClipboard = () => {
        let string = "";
        trips.forEach((trip, index) => {
            string += `<b>Traveler:</b> ${trip.customer?.firstName} ${trip.customer?.lastName}<br/>`;
            if (options?.includes("Primary tour details")) {
                string += "<br/><b>Primary tour details:</b><br/>";
                string += `Prod ID: ${
                    trip.departure?.atlasProdTourId || "–"
                }<br/>`;
                string += `Sales Tour ID: ${
                    trip.departure?.atlasSalesTourId || "–"
                }<br/>`;
                string += `Tour Code: ${trip.departure?.tourCode || "–"}<br/>`;
                string += `Tour Date: ${formatDates(
                    trip.departure?.departureDate,
                    trip.departure?.endDate,
                )}<br/>`;
                string += `Extension: ${
                    trip.extensionSelected &&
                    trip.departure?.extensionDeparture?.name
                        ? trip.departure?.extensionDeparture?.name
                        : "–"
                }<br/>`;
                string += `Extension Date: ${formatDates(
                    trip.departure?.extensionDeparture?.startDate,
                    trip.departure?.extensionDeparture?.endDate,
                )}<br/>`;
                string += `TFD: ${
                    trip.departure?.tourFinalizationDate
                        ? dayjs
                              .utc(trip.departure?.tourFinalizationDate)
                              .format("MMM D, YYYY")
                        : "–"
                }<br/>`;
                string += `T&C: ${
                    trip.termsAndConditions ? "Complete" : "Incomplete"
                }<br/>`;
                if (trip.hasCompletedTsaInformation) {
                    string += `TSA: Complete<br/>`;
                } else if (
                    trip.hasCompletedPassportInformation ||
                    trip.hasCompletedTravelerDetails
                ) {
                    string += `TSA: Partial<br/>`;
                } else {
                    string += `TSA: Incomplete<br/>`;
                }
                if (
                    trip.hasEmergencyContacts &&
                    trip.hasCompletedOnTourContact
                ) {
                    string += `EC: Complete<br/>`;
                } else if (
                    trip.hasEmergencyContacts ||
                    trip.hasCompletedOnTourContact
                ) {
                    string += `EC: Partial<br/>`;
                } else {
                    string += `EC: Incomplete<br/>`;
                }
                string += `TD: ${
                    trip.hasCompletedHealthNotes ? "Complete" : "Incomplete"
                }<br/>`;

                string += `Tour status: ${trip.departure?.status || "–"}<br/>`;
                string += `Accommodation level: ${
                    trip.departure?.tourContent?.accommodationLevel?.title ||
                    "–"
                }<br/>`;
                string += `Prod requests: ${
                    trip.departure?.atlasSalesTourId?.length
                        ? getAtlasProdRequestsLink(
                              trip.departure.atlasSalesTourId,
                          )
                        : "–"
                }<br/>`;
                if (trip.groupTrip?.groupLeaderCustomer) {
                    string += `Group leader: ${trip.groupTrip.groupLeaderCustomer.firstName} ${trip.groupTrip.groupLeaderCustomer.lastName}<br/>`;
                }
                if (trip.hasAutoPay === true) {
                    string += "AutoPay: Yes<br/>";
                }
                string += `Trip status: ${trip.status || "–"}<br/>`;
                string += `Customer type: ${trip.customerType}<br/>`;
                string += `Balance: ${
                    trip.order?.balance
                        ? new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                          }).format(trip.order?.balance)
                        : "–"
                }<br/>`;
            }

            if (options?.includes("TSA")) {
                string += "<br/><b>TSA:</b><br/>";
                string += `First name: ${
                    trip.tsaInformation?.firstName || "–"
                }<br/>`;
                string += `Middle name: ${
                    trip.tsaInformation?.middleName || "–"
                }<br/>`;
                string += `Last name: ${
                    trip.tsaInformation?.lastName || "–"
                }<br/>`;
                string += `Suffix: ${trip.tsaInformation?.suffix || "–"}<br/>`;
                string += `Gender: ${trip.tsaInformation?.gender || "–"}<br/>`;
                string += `Date of birth: ${
                    trip.tsaInformation?.dateOfBirth
                        ? dayjs
                              .utc(trip.tsaInformation?.dateOfBirth)
                              .format("MM/DD/YYYY")
                        : "–"
                }<br/>`;
                string += `Passport number: ${
                    trip.tsaInformation?.maskedIdentityDocumentNumber || "–"
                }<br/>`;
                string += `Expiration: ${
                    trip.tsaInformation?.expirationDate || "–"
                }<br/>`;
            }

            if (options?.includes("Flights")) {
                string += "<br/><b>Flights:</b><br/>";
                const flightsInfo = trip.flightsRequested;
                if (trip.isLandOnly) {
                    string += "Status: L01<br/>";
                    string += "Gateway -<br/>";
                } else if (!flightsInfo) {
                    string += "Status: -<br/>";
                } else {
                    const departureFlight = flightsInfo.departureFlight;
                    const returnFlight = flightsInfo.returnFlight;

                    string += `${
                        departureFlight.startDate
                            ? dayjs(departureFlight.startDate)
                                  .utc()
                                  .format("MMM D, YYYY")
                            : "–"
                    }: ${departureFlight.originCode ?? "–"} > ${
                        departureFlight.destinationCode ?? "–"
                    }<br/>`;

                    string += `${
                        returnFlight.startDate
                            ? dayjs(returnFlight.startDate)
                                  .utc()
                                  .format("MMM D, YYYY")
                            : "–"
                    }: ${returnFlight.originCode ?? "–"} > ${
                        returnFlight.destinationCode ?? "–"
                    }<br/>`;
                }
            }

            if (options?.includes("Transfers")) {
                string += "<br/><b>Transfers:</b><br/>";
                const transferInfo = [
                    ...(trip?.airportTransferFlightInformation ?? []),
                ];
                const sortedTransfers = transferInfo
                    .sort((flight1, flight2) =>
                        dayjs
                            .utc(flight1?.departureDate)
                            .diff(dayjs.utc(flight2?.departureDate)),
                    )
                    .map((flight) => ({
                        arrivalGateway: flight?.gatewayTo,
                        departureGateway: flight?.gatewayFrom,
                        departDate: flight?.departureDate,
                        departureTime: flight?.departureTime,
                        arrivalDate: flight?.arrivalDate,
                        arrivalTime: flight?.arrivalTime,
                        pnr: flight?.pnr,
                        flightNumber: flight?.flightNumber,
                        airline: flight?.airline,
                    }));

                const arrivingTransfer = sortedTransfers[0];
                const departingTransfer = sortedTransfers[1];
                if (transferInfo.length < 1) {
                    string += "N/A: –<br/>";
                } else {
                    if (arrivingTransfer) {
                        const formattedArrivalDate = dayjs
                            .utc(arrivingTransfer.arrivalDate)
                            .format("MMM D, YYYY");
                        const formattedArrivalTime = dayjs
                            .utc(arrivingTransfer.arrivalTime)
                            .format("HH:mm");

                        string += `${formattedArrivalDate} at ${formattedArrivalTime}: ${arrivingTransfer.arrivalGateway?.toUpperCase()}<br/>
                        ${arrivingTransfer.airline?.toUpperCase()} ${
                            arrivingTransfer.flightNumber
                        }<br/>`;
                        string += `PNR: ${arrivingTransfer.pnr?.toUpperCase()}<br/>`;
                    }

                    if (departingTransfer) {
                        const formattedDepartDate = dayjs
                            .utc(departingTransfer.departDate)
                            .format("MMM D, YYYY");
                        const formattedDepartTime = dayjs
                            .utc(departingTransfer.departureTime)
                            .format("HH:mm");

                        string += `${formattedDepartDate} at ${formattedDepartTime}: ${departingTransfer.arrivalGateway?.toUpperCase()}<br/>
                        ${departingTransfer.airline?.toUpperCase()} ${
                            departingTransfer.flightNumber
                        }<br/>`;
                        string += `PNR: ${departingTransfer.pnr?.toUpperCase()}<br/>`;
                    }
                }
            }

            if (options?.includes("Rooming")) {
                string += "<br/><b>Rooming:</b><br/>";
                string += `Roommate: ${
                    trip?.roommateCustomers && trip.roommateCustomers.length > 0
                        ? trip.roommateCustomers
                              .map(
                                  (roommate) =>
                                      `${roommate.firstName} ${roommate.lastName}`,
                              )
                              .join(", ")
                        : !!trip.requestedRoommateNames &&
                          trip.requestedRoommateNames?.length > 0
                        ? trip.requestedRoommateNames?.join(", ")
                        : "–"
                }<br/>`;
                string += `Room type: ${
                    trip.departureRoom?.roomName || "–"
                }<br/>`;
                string += `Bed configuration: ${
                    trip.departureRoom?.bedConfiguration || "–"
                }<br/>`;
                string += `Cabin code: ${
                    trip.departureRoom?.cabinCode || "–"
                }<br/>`;
            }

            if (options?.includes("Extended Stays")) {
                string += "<br/><b>Extended Stays:</b><br/>";
                if (!trip.preStay && !trip.postStay) {
                    string += "Request type: –<br/>";
                    string += "Dates requested: –<br/>";
                    string += "City requested: –<br/>";
                } else {
                    if (trip.preStay) string += `Request type: Prestay<br/>`;
                    string += `Dates requested: ${formatDates(
                        trip.preStay?.startDate,
                        trip.preStay?.endDate,
                    )}<br/>`;
                    string += `City requested: ${
                        trip.preStay?.cityName || "–"
                    }<br/>`;
                    if (trip.postStay) {
                        string += `Request type: Poststay<br/>`;
                        string += `Dates requested: ${formatDates(
                            trip.postStay?.startDate,
                            trip.postStay?.endDate,
                        )}<br/>`;
                        string += `City requested: ${
                            trip.postStay?.cityName || "–"
                        }<br/>`;
                    }
                }
            }

            if (options?.includes("Health")) {
                string += "<br/><b>Health:</b><br/>";
                string += `Medical: ${
                    trip.healthNotes?.medicalConditions || "–"
                }<br/>`;
                string += `Dietary concerns: ${
                    trip.healthNotes?.dietaryRestrictions || "–"
                }<br/>`;
                string += `Mobility: ${trip.healthNotes?.mobility || "–"}<br/>`;
            }

            if (index < trips.length - 1) {
                string += "<br/><br/>";
            }
        });

        copy(string, { format: "text/html" });
    };

    return (
        <Button size="small" onClick={copyToClipboard}>
            Copy to clipboard
        </Button>
    );
};
