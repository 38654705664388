import { useZafClient } from "@hooks/useZafClient";
import { ZafAppLocation } from "@localtypes/zafTypes";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { z } from "zod";
import { BusinessCode } from "@localtypes/generated-gql";

const requestTicketSchema = z.object({
    ticket: z.object({
        id: z.number(),
        tags: z.array(z.string()).optional(),
        brand_id: z.number(),
        requester_id: z.number(),
        custom_fields: z
            .array(
                z.object({
                    id: z.number().int(),
                    value: z.any(),
                }),
            )
            .optional(),
    }),
});
const requestUserSchema = z.object({
    user: z.object({
        user_fields: z
            .object({
                abe_customer_id: z.string().nullish(),
            })
            .optional(),
    }),
});

// externalId is different in ticket schema and we should be explicit
const TicketSchema = z.object({
    externalId: z.string().nullish(),
    id: z.number(),
    tags: z.array(z.string()).optional(),
});

const ResponseSchema = z.object({
    ticket: TicketSchema,
});

export type Response = z.infer<typeof ResponseSchema>;
export type Ticket = z.infer<typeof TicketSchema>;

export const extractParentId = (ticket: Ticket): string | number => {
    const externalId = ticket?.externalId;

    const matches = externalId?.match(/^child_of_(?<parentId>\d+)$/);
    if (matches?.groups?.parentId) {
        // Example externalId: child_of_6623
        // This is used by Myndbend process management Zendesk App.
        return matches?.groups.parentId;
    }

    if (externalId) {
        // Example externalId: zen:side_conversation:f203cfda-9c11-11ed-88d4-da049fee45a1:ticket:6623
        return externalId.split(":").reverse()[0];
    }

    return ticket.id;
};

/**
 * A hook that returns the current ticket or the parent ticket if it's a side conversation. Only supports the ticket editor and ticket sidebar views
 */

export const useTicket = (location: ZafAppLocation) => {
    const zafClient = useZafClient();

    // We only want this query executing in ticket views
    const isEnabled =
        location === "ticket_sidebar" || location === "ticket_editor";

    const {
        data: parentTicket,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ["ticket.id"],
        queryFn: async () => {
            const response = await zafClient?.get("ticket");
            const parsedResponse: Response = ResponseSchema.parse(response);
            // If externalId, we know it's a side conversation
            const parentId = extractParentId(parsedResponse.ticket);

            const ticketResult = await zafClient?.request({
                url: `/api/v2/tickets/${parentId}`,
                type: "GET",
                contentType: "application/json",
            });
            const parsedTicket = requestTicketSchema.parse(ticketResult);

            const userResult = await zafClient?.request({
                url: `/api/v2/users/${parsedTicket.ticket.requester_id}`,
                type: "GET",
                contentType: "application/json",
            });
            const parsedUser = requestUserSchema.parse(userResult);

            return {
                parsedTicket,
                abeCustomerId: parsedUser.user.user_fields?.abe_customer_id,
                isParent: !parsedResponse.ticket.externalId,
            };
        },

        enabled: !!zafClient && isEnabled,
        cacheTime: Infinity,
        staleTime: Infinity,
    });

    useEffect(() => {
        // Listen for changes to the trip id assigned to the ticket
        zafClient?.on(
            `ticket.custom_field_${
                import.meta.env.VITE_TRIP_ID_FIELD_ID
            }.changed`,
            () => refetch(),
        );
    }, [zafClient]);

    const brandId = parentTicket?.parsedTicket.ticket.brand_id.toString();

    let businessId = import.meta.env.VITE_GAT_BUSINESS_ID;
    let businessCode = BusinessCode.Gat;

    if (brandId === import.meta.env.VITE_UB_BRAND_ID) {
        businessId = import.meta.env.VITE_UB_BUSINESS_ID;
        businessCode = BusinessCode.Ub;
    }
    if (brandId === import.meta.env.VITE_ADV_BRAND_ID) {
        businessId = import.meta.env.VITE_ADV_BUSINESS_ID;
        businessCode = BusinessCode.Adv;
    }

    const fieldData = {
        tripId: parentTicket?.parsedTicket.ticket?.custom_fields
            ?.find(
                (field) =>
                    field.id.toString() ===
                    import.meta.env.VITE_TRIP_ID_FIELD_ID,
            )
            ?.value?.toString(),
        customerId:
            parentTicket?.abeCustomerId ??
            parentTicket?.parsedTicket.ticket.custom_fields
                ?.find(
                    (field) =>
                        field.id.toString() ===
                        import.meta.env.VITE_CUSTOMER_ID_FIELD_ID,
                )
                ?.value?.toString(),
        salesTourId: parentTicket?.parsedTicket.ticket.custom_fields
            ?.find(
                (field) =>
                    field.id.toString() ===
                    import.meta.env.VITE_SALES_TOUR_ID_FIELD_ID,
            )
            ?.value?.toString(),
        departureDate: parentTicket?.parsedTicket.ticket.custom_fields
            ?.find(
                (field) =>
                    field.id.toString() ===
                    import.meta.env.VITE_DEPARTURE_DATE_FIELD_ID,
            )
            ?.value?.toString(),
        tourCode: parentTicket?.parsedTicket.ticket.custom_fields
            ?.find(
                (field) =>
                    field.id.toString() ===
                    import.meta.env.VITE_TOUR_CODE_FIELD_ID,
            )
            ?.value?.toString(),
    };

    return {
        ticket: parentTicket?.parsedTicket.ticket,
        loading: isEnabled && isLoading,
        isParentTicket: parentTicket?.isParent,
        fieldData,
        businessId: businessId,
        businessCode,
        refetch,
    };
};
