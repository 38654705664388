import * as Types from '../../../types/generated-gql';

import { gql } from '@apollo/client';
import { ZendeskCustomerFragmentDoc } from '../../fragments/generated/CustomerFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerQueryVariables = Types.Exact<{
  id: Types.Scalars['ObjectId'];
}>;


export type CustomerQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', _id: string, firstName?: string | null, lastName?: string | null, phoneNumber?: any | null, email?: any | null, address?: { __typename?: 'Address', street1: string, street2?: string | null, city: string, state: string, postalCode: string, country: string } | null, legacyIds?: { __typename?: 'LegacyCustomerIds', phxIndividualIds?: Array<string> | null, jasmineCustomerId?: string | null } | null } | null };


export const CustomerDocument = gql`
    query Customer($id: ObjectId!) {
  customer(_id: $id) {
    ...ZendeskCustomer
  }
}
    ${ZendeskCustomerFragmentDoc}`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
      }
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;