import { ZafAppLocation } from "@localtypes/zafTypes";
import { useEffect, useState } from "react";
import { useCustomer } from "./useCustomer";
import { BusinessCode } from "@localtypes/generated-gql";

const gatBusinessId = import.meta.env.VITE_GAT_BUSINESS_ID;
const ubBusinessId = import.meta.env.VITE_UB_BUSINESS_ID;
const advBusinessId = import.meta.env.VITE_ADV_BUSINESS_ID;

export const useBrandedCustomer = (location: ZafAppLocation) => {
    const [customerId, setCustomerId] = useState<string>();
    const [customerSalesforceId, setCustomerSalesforceId] = useState<
        null | string
    >();
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedCustomerBrand, setSelectedCustomerBrand] =
        useState<BusinessCode>(BusinessCode.Gat);
    const [businessId, setBusinessId] = useState<string>();
    const [hasMultipleIds, setHasMultipleIds] = useState<boolean>(false);
    const inUserSidebar = location === "user_sidebar";

    const {
        abeCustomerId: abeCustomerId,
        salesforceContactId,
        customerName,
        loading: customerLoading,
        ticketBrandId,
    } = useCustomer(location);

    const getBusinessCodeByBrandId = (
        brandId: undefined | string,
    ): BusinessCode => {
        if (brandId === import.meta.env.VITE_GAT_BRAND_ID) {
            return BusinessCode.Gat;
        }
        if (brandId === import.meta.env.VITE_ADV_BRAND_ID) {
            return BusinessCode.Adv;
        }

        return BusinessCode.Ub;
    };

    const setIds = (
        customer: string,
        salesforceContactId: null | string,
        business: "GAT" | "UB" | "ADV",
    ) => {
        setCustomerId(customer);
        setCustomerSalesforceId(salesforceContactId);

        if (business === "GAT") {
            return setBusinessId(gatBusinessId);
        }
        if (business === "ADV") {
            return setBusinessId(advBusinessId);
        }
        setBusinessId(ubBusinessId);
    };

    useEffect(() => {
        if (customerLoading) {
            setLoading(true);
            return;
        }

        if (!abeCustomerId) {
            return;
        }

        setIds(
            abeCustomerId,
            salesforceContactId ?? null,
            selectedCustomerBrand,
        );
        setSelectedCustomerBrand(getBusinessCodeByBrandId(ticketBrandId));
        setHasMultipleIds(false);
        setLoading(false);
    }, [
        ticketBrandId,
        salesforceContactId,
        customerName,
        selectedCustomerBrand,
        inUserSidebar,
        loading,
        customerLoading,
        abeCustomerId,
    ]);

    return {
        customerId,
        customerName,
        customerSalesforceId,
        selectedCustomerBrand,
        businessId,
        loading: loading || customerLoading,
        inUserSidebar,
        hasMultipleIds,
        setSelectedCustomerBrand,
    };
};
