import { ContainerHeightResize } from "@components/container-height-resize";
import { Route, Routes } from "react-router-dom";
import { RequesterSidebar } from "./screens/requester-sidebar";

export const RequesterDetails: React.FC = () => {
    return (
        <ContainerHeightResize>
            <Routes>
                <Route
                    path="ticket_sidebar"
                    element={<RequesterSidebar location="ticket_sidebar" />}
                />
                <Route
                    path="user_sidebar"
                    element={<RequesterSidebar location="user_sidebar" />}
                />
            </Routes>
        </ContainerHeightResize>
    );
};
