import { useEffect, useState } from "react";
import { ZafClient } from "../types/zafTypes";

let zafClient: ZafClient | undefined;

export const useZafClient = () => {
    const [client, setClient] = useState(zafClient);

    useEffect(() => {
        if (!client && typeof window.ZAFClient !== "undefined") {
            zafClient = window.ZAFClient.init();

            setClient(zafClient);
        }
    }, [client]);

    return client;
};
