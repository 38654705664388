import { CopyTourToClipboardButton } from "@components/copy-tour-to-clipboard-button/CopyTourToClipboardButton";
import { Button } from "@zendeskgarden/react-buttons";
import { Col, Row } from "@zendeskgarden/react-grid";
import { ReactComponent as NewWindowIcon } from "@zendeskgarden/svg-icons/src/12/new-window-stroke.svg";
import styled from "styled-components";
import {
    DetailsComponentProps,
    LinkTicketButtonState,
    infoDisplayOptions,
} from "./TourDetailsView";
import { useZafClient } from "@hooks/useZafClient";
import { useZafGraphqlClient } from "@hooks/useZafGraphqlClient";
import { updateTicketFields } from "@hooks/updateTicketFields";

const StyledRow = styled(Row)(({ theme }) => ({
    marginTop: "16px", // base theme only provides 12px (sm) and 20px (md)
    marginBottom: theme.space.md,
}));

type TourDetailsButtonRowProps = DetailsComponentProps & {
    showNameOnOrderButton: boolean;
    showCopyTourToClipboardButton: boolean;
    requesterTripId: string;
    linkTicketButtonState: LinkTicketButtonState;
    showOpenOrderButton: boolean;
};

export const TourDetailsButtonRow: React.FC<TourDetailsButtonRowProps> = ({
    trip,
    showNameOnOrderButton,
    showCopyTourToClipboardButton,
    requesterTripId,
    linkTicketButtonState,
    showOpenOrderButton,
}) => {
    const zafClient = useZafClient();
    const zafGraphqlClient = useZafGraphqlClient();

    const handleOpenOrderInSalesforce = () => {
        const url = new URL(
            `/apex/BookingRedirectPage?orderId=${trip.order?.id}`,
            import.meta.env.VITE_SALES_FORCE_URL,
        );

        window.open(url.href, "_blank", "noopener,noreferrer");
    };

    return (
        <StyledRow>
            <Col
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "12px",
                    flexWrap: "wrap",
                }}
            >
                {showCopyTourToClipboardButton ? (
                    <CopyTourToClipboardButton
                        trips={[trip]}
                        options={[...infoDisplayOptions]}
                    />
                ) : null}
                {showOpenOrderButton ? (
                    <Button onClick={handleOpenOrderInSalesforce} size="small">
                        Open{" "}
                        {showNameOnOrderButton &&
                            trip.customer?.firstName &&
                            `${trip.customer.firstName}'s`}{" "}
                        order
                        <Button.EndIcon>
                            <NewWindowIcon />
                        </Button.EndIcon>
                    </Button>
                ) : null}
                <>
                    {linkTicketButtonState !== "Hidden" ? (
                        <Button
                            disabled={linkTicketButtonState === "Disabled"}
                            size="small"
                            onClick={() =>
                                updateTicketFields(
                                    zafClient,
                                    zafGraphqlClient,
                                    requesterTripId,
                                ).then(() => window.location.reload())
                            }
                        >
                            Link selected tour to ticket
                        </Button>
                    ) : null}
                </>
            </Col>
        </StyledRow>
    );
};
