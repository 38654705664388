import { ToastProvider } from "@zendeskgarden/react-notifications";
import { DEFAULT_THEME } from "@zendeskgarden/react-theming";
import { HTMLAttributes } from "react";
import { Route, Routes } from "react-router-dom";
import { BulkTicketsView } from "./screens/bulk-tickets-view";

const topProps = {
    style: { top: DEFAULT_THEME.space.base * 3 },
} as HTMLAttributes<HTMLDivElement>;
const bottomProps = {
    style: { bottom: DEFAULT_THEME.space.base * 3 },
} as HTMLAttributes<HTMLDivElement>;
const toastPlacementProps = {
    "top-start": topProps,
    top: topProps,
    "top-end": topProps,
    "bottom-start": bottomProps,
    bottom: bottomProps,
    "bottom-end": bottomProps,
};

export const BulkTickets: React.FC = () => {
    return (
        <ToastProvider zIndex={1} placementProps={toastPlacementProps}>
            <Routes>
                <Route path="nav_bar" element={<BulkTicketsView />} />
            </Routes>
        </ToastProvider>
    );
};
