import { useState } from "react";
import { Button } from "@zendeskgarden/react-buttons";
import {
    Modal,
    Header,
    Body,
    Footer,
    FooterItem,
    Close,
} from "@zendeskgarden/react-modals";
import { Row, Col } from "@zendeskgarden/react-grid";
import {
    Field,
    Hint,
    Label,
    Message,
    Textarea,
} from "@zendeskgarden/react-forms";
import { useCustomerEmailSearch } from "./hooks/useCustomerEmailSearch";
import { Dots } from "@zendeskgarden/react-loaders";
import { useDebounce } from "use-debounce";
import { CustomerSearchResult } from "./hooks/useCustomerAutocomplete";
import { useValidateEmailList } from "./hooks/useValidateEmailList";

type CustomerImportModalProps = {
    setCustomers: React.Dispatch<React.SetStateAction<CustomerSearchResult[]>>;
    closeModal: () => void;
};

export const CustomerImportModal: React.FC<CustomerImportModalProps> = ({
    setCustomers,
    closeModal,
}) => {
    const [textContent, setTextContent] = useState<string>("");
    const [debouncedTextContent] = useDebounce(textContent, 200);

    const { emailList, isEmailListValid, emailListValidMsg } =
        useValidateEmailList(debouncedTextContent);

    const { customers, isValidCustomers, validCustomersMsg, isLoading } =
        useCustomerEmailSearch(emailList, isEmailListValid);

    const importHandler = async () => {
        if (!customers) {
            console.error("No valid customers");
            return;
        }
        // Import all the valid customers
        setCustomers((currCustomers) => [
            ...currCustomers,
            // Prevent duplicate customers
            ...customers.filter((newCustomer) =>
                currCustomers.every(
                    (existingCustomer) =>
                        newCustomer.email !== existingCustomer.email,
                ),
            ),
        ]);
        closeModal();
    };

    const validationStatus =
        !debouncedTextContent || isLoading
            ? undefined
            : isEmailListValid && isValidCustomers
            ? "success"
            : "error";
    const validationMsg = !debouncedTextContent
        ? ""
        : !isEmailListValid
        ? emailListValidMsg
        : validCustomersMsg;

    return (
        <Modal isLarge onClose={closeModal}>
            <Header tag="h2">Import Emails</Header>
            <Body>
                <Row>
                    <Col>
                        <Field>
                            <Label>Enter Customer Emails</Label>
                            <Hint>Separate by line breaks or commas</Hint>

                            <Textarea
                                minRows={15}
                                maxRows={25}
                                value={textContent}
                                onChange={(evt) =>
                                    setTextContent(evt.target.value)
                                }
                                placeholder={
                                    "test@test.com\ntest2@test.com,test3@test.com"
                                }
                            />
                            {validationStatus || isLoading ? (
                                <Message
                                    validation={validationStatus}
                                    validationLabel={
                                        validationStatus ?? "Validating"
                                    }
                                >
                                    {isLoading ? (
                                        <Dots delayMS={0} />
                                    ) : (
                                        validationMsg
                                    )}
                                </Message>
                            ) : null}
                        </Field>
                    </Col>
                </Row>
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={closeModal} isBasic>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    <Button
                        isPrimary
                        disabled={validationStatus !== "success"}
                        onClick={importHandler}
                    >
                        Import Emails
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
};
