import { ZendeskBookingTripFragment } from "@graphql/fragments/generated/BookingTripFragment";

export enum ZendeskAppChronology {
    Future = "Future",
    Past = "Past",
    Present = "Present",
}

export type ZendeskAppTrip = {
    id: string;
    status?: string | null;
    chronology?: ZendeskAppChronology | null;
    customerType: string;
    extensionSelected: boolean;
    hasAutoPay?: boolean | null;
    hasCompletedTsaInformation: boolean;
    hasCompletedPassportInformation: boolean;
    hasCompletedTravelerDetails: boolean;
    hasCompletedHealthNotes: boolean;
    hasEmergencyContacts: boolean;
    hasCompletedOnTourContact: boolean;
    isLandOnly: boolean;
    requestedRoommateNames?: Array<string> | null;
    roomReservationId?: string | null;
    customer?: {
        id: string;
        firstName?: string | null;
        lastName: string;
    } | null;
    departure?: {
        id: string;
        departureDate: string;
        name?: string | null;
        tourCode: string;
        status: string;
        endDate: string;
        tourFinalizationDate: string;
        atlasProdTourId?: number | null;
        atlasSalesTourId?: string | null;
        extensionDeparture?: {
            id: string;
            startDate: string;
            endDate: string;
            name?: string | null;
        } | null;
        tourContent?: {
            accommodationLevel?: {
                title?: string | null;
            } | null;
        } | null;
    } | null;
    groupTrip?: ZendeskAppGroupTrip | null;
    airportTransferFlightInformation: ZendeskAppAirportTransferFlightInformation[];
    order?: {
        id: string;
        balance?: number | null;
    } | null;
    termsAndConditions?: {
        id: string;
        acceptedDate: string;
    } | null;
    tsaInformation?: ZendeskBookingTripFragment["tsaInformation"] | null;
    healthNotes: ZendeskAppHealthNotes;
    roommateCustomers: Array<{
        id: string;
        firstName: string;
        lastName: string;
    }> | null;
    preStay?: {
        id: string;
        cityName?: string | null;
        startDate?: string | null;
        endDate?: string | null;
    } | null;
    postStay?: {
        id: string;
        cityName?: string | null;
        startDate?: string | null;
        endDate?: string | null;
    } | null;
    flightsRequested: {
        departureFlight: {
            destinationCode?: string | null;
            originCode?: string | null;
            startDate?: string | null;
        };
        returnFlight: {
            destinationCode?: string | null;
            originCode?: string | null;
            startDate?: string | null;
        };
    };
    departureRoom?: ZendeskAppDepartureRoom | null;
};

export type ZendeskAppDepartureRoom = {
    id: string;
    cabinCode?: string | null;
    roomName?: string | null;
    bedConfiguration?: string | null;
};

export type ZendeskAppAirportTransferFlightInformation = {
    departureDate?: string | null;
    departureTime?: string | null;
    arrivalDate?: string | null;
    arrivalTime?: string | null;
    gatewayFrom?: string | null;
    gatewayTo?: string | null;
    flightNumber?: string | null;
    pnr?: string | null;
    airline?: string | null;
};

export type ZendeskAppGroupTrip = {
    id: string;
    groupLeaderCustomer: {
        id: string;
        firstName?: string | null;
        lastName?: string | null;
    } | null;
};

export type ZendeskAppHealthNotes = {
    dietaryRestrictions: string | null;
    mobility: string | null;
    medicalConditions: string | null;
};
