import { ToastProvider } from "@zendeskgarden/react-notifications";
import { Route, Routes } from "react-router-dom";
import { InvoiceLinkView } from "./screens/invoice-link-view";

export const InvoiceLink: React.FC = () => {
    return (
        <ToastProvider zIndex={1}>
            <Routes>
                <Route path="editor" element={<InvoiceLinkView />} />
            </Routes>
        </ToastProvider>
    );
};
