import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import {
    Checkbox,
    Field,
    Hint,
    Input,
    Label,
} from "@zendeskgarden/react-forms";
import { useState } from "react";
import styled from "styled-components";
import {
    TicketContent,
    TicketStatus,
    useCreateTicketsMutation,
} from "./hooks/useCreateTicketsMutation";
import { CustomerMultiselect } from "./CustomerMultiselect";
import { RichTextEditor } from "./RichTextEditor";
import { CreateTicketsButton } from "./CreateTicketsButton";
import { ReactComponent as PublicReplyIcon } from "@zendeskgarden/svg-icons/src/12/arrow-left-up-stroke.svg";
import { useAgent } from "./hooks/useAgent";
import { CustomerSearchResult } from "./hooks/useCustomerAutocomplete";
import { useCreateTicketsResultToast } from "./hooks/useCreateTicketsResultToast";
import { CustomerBrandDropdown } from "@components/customer-brand-dropdown";
import { BusinessCode } from "@localtypes/generated-gql";

const StyledRow = styled(Row)(({ theme }) => ({
    "& + *": {
        marginTop: theme.space.md,
    },
}));
const StyledGrid = styled(Grid)(({ theme }) => ({
    maxWidth: 1000,
    paddingTop: theme.space.xs,
    marginTop: theme.space.md,
}));

export const BulkTicketsView: React.FC = () => {
    const [requesters, setRequesters] = useState<CustomerSearchResult[]>([]);
    const [subject, setSubject] = useState<string>("");
    const [comment, setComment] = useState<string>("");
    const [assignToAgent, setAssignToAgent] = useState<boolean>(true);
    const [ticketStatus, setTicketStatus] = useState<TicketStatus>("new");
    const [selectedCustomerBrand, setSelectedCustomerBrand] =
        useState<BusinessCode>(BusinessCode.Gat);
    const agent = useAgent();
    const { mutateAsync: createTicketsMutation, isLoading } =
        useCreateTicketsMutation();

    const { successToast, errorToast } = useCreateTicketsResultToast();
    const brandId =
        selectedCustomerBrand === "GAT"
            ? parseInt(import.meta.env.VITE_GAT_BRAND_ID)
            : parseInt(import.meta.env.VITE_UB_BRAND_ID);

    const createTicketsHandler = async () => {
        // Quill always inserts an extraneous `<p><br></p>` at start of editor content
        // https://github.com/quilljs/quill/issues/1235
        const trimmedComment = comment.replace(/^<p><br><\/p>/, "");
        const tickets: TicketContent[] = requesters.map((requester) => ({
            subject: subject,
            comment: {
                html_body: trimmedComment,
            },
            status: ticketStatus,
            requester_id: requester.customerId,
            assignee_id: assignToAgent && agent ? agent.id : null,
            tags: ["bulk-ticket"],
            brand_id: brandId,
        }));

        try {
            const result = await createTicketsMutation({ tickets, requesters });

            if (result.missingRequesters.length) {
                return errorToast(result);
            }

            successToast(result);

            // Clear the form on success
            setRequesters([]);
            setSubject("");
            setComment("");
            setTicketStatus("new");
            setSelectedCustomerBrand(BusinessCode.Gat);
        } catch (err) {
            if (err instanceof Error) {
                errorToast({
                    messages: [err.message],
                    missingRequesters: [],
                    ticketIds: [],
                });
            }
        }
    };

    return (
        <StyledGrid>
            <StyledRow>
                <Col>
                    <CustomerBrandDropdown
                        selectedCustomerBrand={selectedCustomerBrand}
                        setSelectedCustomerBrand={setSelectedCustomerBrand}
                    />
                </Col>
            </StyledRow>
            <StyledRow>
                <Col>
                    <CustomerMultiselect
                        customers={requesters}
                        setCustomers={setRequesters}
                        disabled={isLoading}
                    />
                </Col>
            </StyledRow>
            <StyledRow>
                <Col>
                    <Field>
                        <Label>Subject*</Label>
                        <Input
                            isCompact
                            onChange={(evt) => setSubject(evt.target.value)}
                            value={subject}
                            disabled={isLoading}
                        />
                    </Field>{" "}
                </Col>
            </StyledRow>
            <StyledRow>
                <Col>
                    <Field>
                        <Label>
                            <PublicReplyIcon style={{ marginRight: "6px" }} />
                            Public reply*
                        </Label>
                        <Hint>Initial ticket description</Hint>
                        <RichTextEditor
                            text={comment}
                            setText={setComment}
                            disabled={isLoading}
                        />
                    </Field>
                </Col>
            </StyledRow>

            <StyledRow>
                <Col textAlign="start">
                    <Field>
                        <Checkbox
                            checked={assignToAgent}
                            onChange={() => setAssignToAgent((curr) => !curr)}
                        >
                            <Label>Assign tickets to current agent</Label>
                        </Checkbox>
                    </Field>
                </Col>
                <Col textAlign="end">
                    <CreateTicketsButton
                        onClickHandler={createTicketsHandler}
                        ticketStatus={ticketStatus}
                        setTicketStatus={setTicketStatus}
                        disabled={
                            requesters.length > 100 ||
                            !requesters.length ||
                            subject === "" ||
                            comment === ""
                        }
                        isLoading={isLoading}
                        numRequesters={requesters.length}
                        assignToAgent={assignToAgent}
                    />
                </Col>
            </StyledRow>
        </StyledGrid>
    );
};
