import { useEffect } from "react";
import { useZafClient } from "./useZafClient";

// Be aware of resize limitations based on app location:
//   https://developer.zendesk.com/api-reference/apps/apps-core-api/core_api/#resize
export const useResize = ({
    width,
    height,
}: {
    width: string;
    height: string;
}) => {
    const zafClient = useZafClient();

    useEffect(() => {
        zafClient?.invoke?.("resize", { width, height });
    }, [zafClient, width, height]);
};
