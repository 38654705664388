import {
    Close,
    Notification,
    Title,
    useToast,
} from "@zendeskgarden/react-notifications";

export const useErrorToast = () => {
    const { addToast } = useToast();

    return (errorMsg: string) => {
        addToast(
            ({ close }) => (
                <Notification type="error">
                    <Title>Error</Title>
                    Unable to generate PDF: {errorMsg}
                    <Close aria-label="Close" onClick={close} />
                </Notification>
            ),
            { placement: "top-end", autoDismiss: false },
        );
    };
};
