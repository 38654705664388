import { useMemo } from "react";

export const useValidateEmailList = (emailText: string) => {
    return useMemo(() => {
        const emailList =
            emailText === ""
                ? []
                : emailText
                      .trim()
                      .split("\n")
                      .flatMap((val) => val.split(","))
                      .map((val) => val.trim())
                      .filter((val) => val !== "");

        // Email regex from: https://www.regular-expressions.info/email.html
        const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const numValidEmails = emailList.reduce(
            (currNumEmails, email) =>
                email.match(emailRegEx) ? currNumEmails + 1 : currNumEmails,
            0,
        );
        const numInvalidEmails = emailList.length - numValidEmails;
        const numDuplicateEmails = emailList.length - new Set(emailList).size;
        const isValid =
            numDuplicateEmails === 0 &&
            numInvalidEmails === 0 &&
            numValidEmails <= 100 &&
            numValidEmails > 0;

        let validationMsg = "";
        if (numDuplicateEmails || numInvalidEmails) {
            validationMsg +=
                (numDuplicateEmails ? `${numDuplicateEmails} duplicates` : "") +
                (numDuplicateEmails && numInvalidEmails ? " / " : "") +
                (numInvalidEmails
                    ? `${numInvalidEmails} malformed emails`
                    : "");
        } else {
            validationMsg =
                `${numValidEmails} well-formed emails` +
                (numValidEmails > 100 ? " exceeds the max of 100" : "");
        }

        return {
            emailList,
            isEmailListValid: isValid,
            emailListValidMsg: validationMsg,
        };
    }, [emailText]);
};
