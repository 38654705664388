import { useSortedTripTravelers } from "@hooks/useSortedTripTravelers";
import {
    Dropdown,
    Field,
    HeaderItem,
    Item,
    Label,
    MediaBody,
    MediaFigure,
    Menu,
    Select,
    Separator,
} from "@zendeskgarden/react-dropdowns";
import { ReactComponent as UserFillIcon } from "@zendeskgarden/svg-icons/src/12/user-circle-fill.svg";
import { ReactComponent as UserStrokeIcon } from "@zendeskgarden/svg-icons/src/12/user-circle-stroke.svg";
import React, { useState } from "react";
import styled from "styled-components";
import { ZendeskAppTrip } from "@localtypes/zendesk-app.types";

const RequesterIcon = styled(UserFillIcon)(({ theme }) => ({
    color: theme.palette.grey[600],
}));
const TravelerIcon = styled(UserStrokeIcon)(({ theme }) => ({
    color: theme.palette.grey[600],
}));

export type TravelersDropdownProps = {
    requesterTripId: string;
    selectedTravelerTripId: string | undefined;
    setSelectedTravelerTripId: React.Dispatch<
        React.SetStateAction<string | undefined>
    >;
    businessId: string;
    trips: ZendeskAppTrip[];
};

export const TravelersDropdown: React.FC<TravelersDropdownProps> = ({
    requesterTripId,
    selectedTravelerTripId,
    setSelectedTravelerTripId,
    trips,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const { sortedOrders, requesterTrip } = useSortedTripTravelers(
        requesterTripId,
        trips,
    );

    if (!trips?.length) return null;

    const selectedTravelerTrip = trips.find(
        (trip) => trip.id === selectedTravelerTripId,
    );
    if (!requesterTrip) return null;

    return (
        <Dropdown
            selectedItem={selectedTravelerTrip}
            onSelect={(item: ZendeskAppTrip) => {
                setSelectedTravelerTripId(item?.id);
            }}
            downshiftProps={{
                itemToString: (item: ZendeskAppTrip) => item?.id ?? "",
            }}
            onStateChange={(change) => {
                if (change.isOpen !== undefined) setIsOpen(change.isOpen);
            }}
        >
            <Field>
                <Label>Traveler</Label>
                <Select
                    isCompact
                    start={
                        selectedTravelerTrip?.id === requesterTrip.id ? (
                            <RequesterIcon />
                        ) : (
                            <TravelerIcon />
                        )
                    }
                >
                    <div>
                        {!!selectedTravelerTrip?.customer?.firstName ||
                        !!selectedTravelerTrip?.customer?.lastName ? (
                            <>
                                {selectedTravelerTrip?.customer?.firstName}{" "}
                                {selectedTravelerTrip?.customer?.lastName}
                                {isOpen &&
                                selectedTravelerTrip?.id === requesterTrip.id
                                    ? " (Requester)"
                                    : null}
                            </>
                        ) : (
                            "Selected customer"
                        )}
                    </div>
                </Select>
            </Field>
            <Menu>
                {sortedOrders.map(({ orderId, trips }, orderIdx) => (
                    <React.Fragment key={trips[0].order?.id}>
                        <HeaderItem>
                            {orderId === requesterTrip.order?.id
                                ? "Requester order"
                                : `Order ${orderIdx}`}
                        </HeaderItem>
                        {trips.map((traveler) => (
                            <Item key={traveler.id} value={traveler}>
                                <MediaFigure>
                                    {traveler.id === requesterTrip.id ? (
                                        <RequesterIcon />
                                    ) : (
                                        <TravelerIcon />
                                    )}
                                </MediaFigure>
                                <MediaBody>
                                    {traveler?.customer?.firstName}{" "}
                                    {traveler?.customer?.lastName} (
                                    {traveler.roomName})
                                </MediaBody>
                            </Item>
                        ))}
                        {orderIdx < sortedOrders.length - 1 && <Separator />}
                    </React.Fragment>
                ))}
            </Menu>
        </Dropdown>
    );
};
