import { useZafClient } from "@hooks/useZafClient";
import { useEffect, useState } from "react";
import { number, string, z } from "zod";

export const userResponseSchema = z.object({
    currentUser: z.object({
        id: number(),
        name: string(),
        email: string().email(),
    }),
});
export type User = z.infer<typeof userResponseSchema.shape.currentUser>;

export const useAgent = () => {
    const zafClient = useZafClient();

    const [agent, setAgent] = useState<User>();

    useEffect(() => {
        if (!zafClient) return;
        (async () => {
            const response = await zafClient.get("currentUser");
            const userResponseParse = userResponseSchema.safeParse(response);
            if (!userResponseParse.success) {
                console.error(
                    `Invalid user agent returned from useAgent: ${userResponseParse.error.message}`,
                );
                return;
            }
            const agent = userResponseParse.data.currentUser;
            setAgent(agent);
        })();
    }, [zafClient]);

    return agent;
};
