import {
    Close,
    Notification,
    Title,
    useToast,
} from "@zendeskgarden/react-notifications";
import { Paragraph } from "@zendeskgarden/react-typography";
import pluralize from "pluralize";
import styled from "styled-components";
import { CreateTicketsMutationResult } from "./useCreateTicketsMutation";

const StyledNotification = styled(Notification)({
    maxWidth: 450,
});

export const useCreateTicketsResultToast = () => {
    const { addToast } = useToast();

    const successToast = (result: CreateTicketsMutationResult) => {
        addToast(
            ({ close }) => (
                <StyledNotification data-testid="successToast" type="success">
                    <Title>Success</Title>
                    Successfully created{" "}
                    {pluralize("ticket", result.ticketIds.length, true)}:{" "}
                    {result.ticketIds.map((id) => (
                        <span key={id}>#{id} </span>
                    ))}
                    <Close aria-label="Close" onClick={close} />
                </StyledNotification>
            ),
            { placement: "top-end", autoDismiss: false },
        );
    };

    const errorToast = (result: CreateTicketsMutationResult) => {
        addToast(
            ({ close }) => (
                <StyledNotification data-testid="errorToast" type="error">
                    <Title>Ticket Creation Error</Title>
                    {result.messages.length ? (
                        <Paragraph>
                            Error in creating tickets:{" "}
                            {result.messages.map((msg) => (
                                <span key={msg}>&quot;{msg}&quot; </span>
                            ))}
                        </Paragraph>
                    ) : null}
                    {result.missingRequesters.length ? (
                        <Paragraph>
                            Failed to create tickets for the following{" "}
                            {pluralize(
                                "customers",
                                result.missingRequesters.length,
                                true,
                            )}
                            :{" "}
                            {result.missingRequesters.map((requester) => (
                                <span key={requester.customerId}>
                                    {requester.email}{" "}
                                </span>
                            ))}
                        </Paragraph>
                    ) : null}
                    {result.ticketIds.length ? (
                        <Paragraph>
                            Successfully created{" "}
                            {pluralize("ticket", result.ticketIds.length, true)}
                            :{" "}
                            {result.ticketIds.map((id) => (
                                <span key={id}>#{id} </span>
                            ))}
                        </Paragraph>
                    ) : null}
                    <Close aria-label="Close" onClick={close} />
                </StyledNotification>
            ),
            { placement: "top-end", autoDismiss: false },
        );
    };

    return {
        successToast,
        errorToast,
    };
};
