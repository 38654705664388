import dayjs from "dayjs";

export const formatDates = (
    startDate?: string | null,
    endDate?: string | null,
) => {
    if (!startDate) {
        return "–";
    }
    const startDateFormat =
        endDate && dayjs.utc(startDate).isSame(endDate, "year")
            ? "MMM D"
            : "MMM D, YYYY";
    const formattedStartDate = dayjs.utc(startDate).format(startDateFormat);
    if (endDate) {
        const endDateFormat = dayjs.utc(startDate).isSame(endDate, "month")
            ? "D, YYYY"
            : "MMM D, YYYY";
        const formattedEndDate = dayjs.utc(endDate).format(endDateFormat);
        return `${formattedStartDate} - ${formattedEndDate}`;
    }
    return formattedStartDate;
};
