import groupBy from "lodash.groupby";
import orderBy from "lodash.orderby";
import { ZendeskAppTrip } from "@localtypes/zendesk-app.types";

const sortOrders = (trips: ZendeskAppTrip[], requesterTrip: ZendeskAppTrip) => {
    // sort trips: requester first, then by room config, then by last/first name
    const sortedTrips = orderBy(
        trips,
        [
            (trip) => trip.id === requesterTrip.id,
            (trip) => trip.departureRoom?.bedConfiguration,
            (trip) => trip.customer?.lastName,
            (trip) => trip.customer?.firstName,
        ],
        ["desc", "asc", "asc", "asc"],
    );

    // Group trips by order
    const sortedTripsByOrders = groupBy(sortedTrips, (trip) => trip.order?.id);
    const sortedTripsByOrdersArray = Object.entries(sortedTripsByOrders).map(
        ([orderId, trips]) => ({
            orderId,
            trips,
        }),
    );

    // Sort the orders: requester first, then single orders (sorted by last/first name), then larger trips
    const sortedTripsBySortedOrders = orderBy(
        sortedTripsByOrdersArray,
        [
            (order) => order.orderId === requesterTrip.order?.id,
            (order) => order.trips.length,
            (order) => order.trips[0].customer?.lastName,
            (order) => order.trips[0].customer?.firstName,
        ],
        ["desc", "asc", "asc", "asc"],
    );

    // Assign room name ("S-1", "D-3")
    const roomTypesCount: Record<string, number | undefined> = {};
    const roomReservationNames: Record<string, string> = {};
    const sortedTripsBySortedOrdersWithRooms = sortedTripsBySortedOrders.map(
        (order) => ({
            orderId: order.orderId,
            trips:
                // assign room name to each trip on this order
                order.trips.map((trip) => {
                    const roomReservationId = trip.roomReservationId ?? "";
                    if (!roomReservationNames[roomReservationId]) {
                        // create a name for this reservationId
                        const roomType =
                            trip.departureRoom?.bedConfiguration ?? "?";
                        roomTypesCount[roomType] =
                            1 + (roomTypesCount[roomType] ?? 0);
                        roomReservationNames[
                            roomReservationId
                        ] = `${roomType}-${roomTypesCount[roomType]}`;
                    }
                    return {
                        ...trip,
                        roomName: roomReservationNames[roomReservationId],
                    };
                }),
        }),
    );

    return sortedTripsBySortedOrdersWithRooms;
};

export const useSortedTripTravelers = (
    primaryTripId: string,
    trips: ZendeskAppTrip[],
) => {
    const requesterTrip = trips?.find((trip) => trip.id === primaryTripId);

    const sortedOrders =
        !trips || !requesterTrip ? [] : sortOrders(trips, requesterTrip);

    return {
        sortedOrders,
        requesterTrip,
    };
};
