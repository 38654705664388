import { FlightRows } from "./FlightRows";
import { StyledSectionHeaderCol, StyledSectionTopRow } from "./StyledGrid";
import { DetailsComponentProps } from "./TourDetailsView";

export const FlightDetails: React.FC<DetailsComponentProps> = ({ trip }) => {
    return (
        <>
            <StyledSectionTopRow>
                <StyledSectionHeaderCol>
                    Requested Flights
                </StyledSectionHeaderCol>
            </StyledSectionTopRow>
            <FlightRows trip={trip} />
        </>
    );
};
