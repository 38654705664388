import {
    DataCol,
    DataRow,
    LabelCol,
    StyledSectionHeaderCol,
    StyledSectionTopRow,
} from "./StyledGrid";
import { DetailsComponentProps } from "./TourDetailsView";

export const HealthDetails: React.FC<DetailsComponentProps> = ({ trip }) => {
    return (
        <>
            <StyledSectionTopRow>
                <StyledSectionHeaderCol>Health</StyledSectionHeaderCol>
            </StyledSectionTopRow>
            <DataRow>
                <LabelCol>Medical</LabelCol>
                <DataCol>{trip.healthNotes?.medicalConditions || "–"}</DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Dietary concerns</LabelCol>
                <DataCol>
                    {trip.healthNotes?.dietaryRestrictions || "–"}
                </DataCol>
            </DataRow>
            <DataRow>
                <LabelCol>Mobility</LabelCol>
                <DataCol>{trip.healthNotes?.mobility || "–"}</DataCol>
            </DataRow>
        </>
    );
};
