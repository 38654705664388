import { ZafClient } from "@localtypes/zafTypes";
import { DocumentNode } from "@apollo/client";
import { print } from "graphql";

export class ZafGraphqlClient {
    constructor(private readonly zafClient: ZafClient) {}

    async execute<T>(
        documentNode: DocumentNode,
        variables?: object,
    ): Promise<T> {
        const response = await this.zafClient?.request<unknown, { data: T }>({
            url: `${import.meta.env.VITE_WOJO_API_URL}/graphql`,
            type: "POST",
            cors: false,
            contentType: "application/json",
            data: JSON.stringify({
                query: print(documentNode),
                variables: variables ?? {},
            }),
            headers: {
                "x-api-key": "{{setting.apiKey}}",
                "apollographql-client-name": "wojo-zendesk-apps",
            },
            secure: true,
        });
        return response.data;
    }
}
