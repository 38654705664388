import { useEffect, useState } from "react";
import { useZafClient } from "@hooks/useZafClient";
import { ZafGraphqlClient } from "@graphql/zaf-graphql-client";

let zafGraphqlClient: ZafGraphqlClient | undefined;

export const useZafGraphqlClient = () => {
    const [graphqlClient, setGraphqlClient] = useState(zafGraphqlClient);
    const zafClient = useZafClient();

    useEffect(() => {
        if (!graphqlClient && zafClient) {
            setGraphqlClient(new ZafGraphqlClient(zafClient));
        }
    }, [graphqlClient, zafClient]);

    return graphqlClient;
};
