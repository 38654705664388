import { useSortedTripTravelers } from "@hooks/useSortedTripTravelers";
import {
    Dropdown,
    Field,
    HeaderItem,
    Item,
    Label,
    MediaBody,
    MediaFigure,
    Menu,
    Multiselect,
    Separator,
} from "@zendeskgarden/react-dropdowns";
import { Tag } from "@zendeskgarden/react-tags";
import { ReactComponent as UserFillIcon } from "@zendeskgarden/svg-icons/src/12/user-circle-fill.svg";
import { ReactComponent as UserStrokeIcon } from "@zendeskgarden/svg-icons/src/12/user-circle-stroke.svg";
import React, { useState } from "react";
import styled from "styled-components";
import { ZendeskAppTrip } from "@localtypes/zendesk-app.types";

const RequesterIcon = styled(UserFillIcon)(({ theme }) => ({
    color: theme.palette.grey[600],
}));
const TravelerIcon = styled(UserStrokeIcon)(({ theme }) => ({
    color: theme.palette.grey[600],
}));

export type TravelersMultiselectProps = {
    requesterTripId: string;
    travelersTripIds: string[];
    setTravelersTripIds: React.Dispatch<React.SetStateAction<string[]>>;
    trips: ZendeskAppTrip[];
};

export const TravelersMultiselect: React.FC<TravelersMultiselectProps> = ({
    requesterTripId,
    setTravelersTripIds,
    travelersTripIds,
    trips,
}) => {
    const [inputValue, setInputValue] = useState("");
    const [matchingOptions, setMatchingOptions] = useState<string[]>([]);

    const { sortedOrders, requesterTrip } = useSortedTripTravelers(
        requesterTripId,
        trips,
    );

    const selectedTravelerTrips = travelersTripIds.map((selectedTripId) =>
        trips.find((trip) => trip.id === selectedTripId),
    );
    if (!requesterTrip) return null;

    const handleInputValueChange = (value: string) => {
        setInputValue(value);
        const inputTrimmed = value.trim().toLowerCase();

        // autocomplete based on first or lastname
        const matchedOptions = trips
            .filter((trip) => {
                return (
                    inputTrimmed &&
                    trip?.customer?.firstName &&
                    (trip.customer.firstName
                        .trim()
                        .toLowerCase()
                        .startsWith(inputTrimmed) ||
                        trip.customer.lastName
                            ?.trim()
                            .toLowerCase()
                            .startsWith(inputTrimmed))
                );
            })
            .map((trip) => trip.id ?? "")
            .filter(Boolean);
        setMatchingOptions(matchedOptions);
    };

    return (
        <Dropdown
            inputValue={inputValue}
            onInputValueChange={handleInputValueChange}
            selectedItems={selectedTravelerTrips}
            onSelect={(items: ZendeskAppTrip[]) =>
                setTravelersTripIds(
                    items.map((trip) => trip.id ?? "").filter(Boolean),
                )
            }
            downshiftProps={{
                itemToString: (item: ZendeskAppTrip) => item?.id ?? "",
            }}
        >
            <Field>
                <Label>Traveler(s) on request</Label>
                <Multiselect
                    renderItem={({
                        value,
                        removeValue,
                    }: {
                        value?: ZendeskAppTrip;
                        removeValue: () => void;
                    }) => (
                        <Tag>
                            <Tag.Avatar>
                                {value?.id === requesterTrip.id ? (
                                    <RequesterIcon />
                                ) : (
                                    <TravelerIcon />
                                )}
                            </Tag.Avatar>
                            <span>
                                {value?.customer?.firstName ?? ""}{" "}
                                {value?.customer?.lastName ?? ""}
                            </span>
                            <Tag.Close onClick={() => removeValue()} />
                        </Tag>
                    )}
                    isCompact
                />
            </Field>
            <Menu>
                {sortedOrders.map(({ orderId, trips }, orderIdx) => (
                    <React.Fragment key={trips[0].order?.id}>
                        {!matchingOptions.length && (
                            <HeaderItem>
                                {orderId === requesterTrip.order?.id
                                    ? "Requester order"
                                    : `Order ${orderIdx}`}
                            </HeaderItem>
                        )}
                        {trips
                            .filter(
                                (trip) =>
                                    !matchingOptions.length ||
                                    !trip.id ||
                                    matchingOptions.includes(trip.id),
                            )
                            .map((traveler) => (
                                <Item key={traveler.id} value={traveler}>
                                    <MediaFigure>
                                        {traveler.id === requesterTrip.id ? (
                                            <RequesterIcon />
                                        ) : (
                                            <TravelerIcon />
                                        )}
                                    </MediaFigure>
                                    <MediaBody>
                                        {traveler?.customer?.firstName}{" "}
                                        {traveler?.customer?.lastName} (
                                        {traveler.roomName})
                                    </MediaBody>
                                </Item>
                            ))}
                        {!matchingOptions.length &&
                        orderIdx < sortedOrders.length - 1 ? (
                            <Separator />
                        ) : null}
                    </React.Fragment>
                ))}
            </Menu>
        </Dropdown>
    );
};
