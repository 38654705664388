import { useTicket } from "@hooks/useTicket";
import { ZafAppLocation } from "@localtypes/zafTypes";
import {
    Dropdown,
    Field,
    HeaderItem,
    Item,
    ItemMeta,
    Label,
    Menu,
    Select,
    Separator,
} from "@zendeskgarden/react-dropdowns";
import { Alert, Title } from "@zendeskgarden/react-notifications";
import dayjs from "dayjs";
import { useEffect } from "react";
import styled from "styled-components";
import {
    ZendeskAppTrip,
    ZendeskAppChronology,
} from "@localtypes/zendesk-app.types";
import { TripStatus } from "@localtypes/generated-gql";

const SubText = styled.div(({ theme }) => ({
    fontSize: theme.fontSizes.sm,
}));

const getDPDString = (date: string | undefined) => {
    if (!date) return "? DPD";
    const dpd = dayjs(date)
        .utc()
        .startOf("day")
        .diff(dayjs().utc().startOf("day"), "days");
    return `${dpd} DPD`;
};

const sortTrips = (trips: ZendeskAppTrip[] | null | undefined) => {
    if (!trips) {
        return {
            currentTrips: [],
            upcomingTrips: [],
            pastTrips: [],
            pendingConfirmationTrips: [],
            waitlistedTrips: [],
        };
    }
    const currentTrips = trips.filter(
        (trip) =>
            trip?.chronology === ZendeskAppChronology.Present &&
            trip.status! === TripStatus.Confirmed,
    );
    const upcomingTrips = trips.filter(
        (trip) =>
            trip?.chronology === ZendeskAppChronology.Future &&
            trip.status! === TripStatus.Confirmed,
    );
    const pastTrips = trips.filter(
        (trip) =>
            trip?.chronology === ZendeskAppChronology.Past &&
            trip.status! === TripStatus.Confirmed,
    );
    const pendingConfirmationTrips = trips.filter(
        (trip) => trip.status! === TripStatus.Requested,
    );
    const waitlistedTrips = trips.filter(
        (trip) => trip.status! === TripStatus.Waitlisted,
    );

    return {
        currentTrips: currentTrips,

        // Sort upcoming trips forwards in time
        upcomingTrips: upcomingTrips.sort((trip1, trip2) =>
            dayjs
                .utc(trip1.departure?.departureDate)
                .diff(dayjs.utc(trip2.departure?.departureDate)),
        ),

        // Sort past trips backwards in time
        pastTrips: pastTrips.sort((trip1, trip2) =>
            dayjs
                .utc(trip2.departure?.departureDate)
                .diff(dayjs.utc(trip1.departure?.departureDate)),
        ),

        pendingConfirmationTrips: pendingConfirmationTrips,
        waitlistedTrips: waitlistedTrips,
    };
};

export type CustomerTripsDropdownProps = {
    customerId: string;
    selectedTripId: string | undefined;
    setSelectedTripId: React.Dispatch<React.SetStateAction<string | undefined>>;
    maxHeight?: string;
    labelText?: string;
    isLabelVisible?: boolean;
    isTicketEditable?: boolean;
    inUserSidebar: boolean;
    location: ZafAppLocation;
    trips: ZendeskAppTrip[];
};

export const CustomerTripsDropdown: React.FC<CustomerTripsDropdownProps> = ({
    customerId,
    selectedTripId,
    setSelectedTripId,
    maxHeight,
    labelText = "Selected trip",
    isLabelVisible = false,
    inUserSidebar,
    location,
    trips,
}) => {
    const { fieldData, isParentTicket } = useTicket(location);

    // Set the default selected trip whenever we fetch new trips
    // NOTE: We're NOT doing this in useQuery's onCompleted handler b/c
    //   that handler will be called whenever the cache is updated, even for
    //   the same customerId.
    useEffect(() => {
        if (!trips || selectedTripId) return;

        const {
            currentTrips,
            upcomingTrips,
            pastTrips,
            waitlistedTrips,
            pendingConfirmationTrips,
        } = sortTrips(trips);

        const selectedTrip =
            currentTrips[0] ||
            upcomingTrips[0] ||
            pastTrips[0] ||
            pendingConfirmationTrips[0] ||
            waitlistedTrips[0];

        if (selectedTrip?.id) {
            setSelectedTripId(selectedTrip.id);
        }
    }, [trips, selectedTripId]);

    useEffect(() => {
        if (!selectedTripId) {
            setSelectedTripId(fieldData.tripId ?? "");
        }
    }, [customerId]);

    if (!selectedTripId) {
        return (
            <Alert type="info" style={{ paddingBlock: 8 }}>
                {isLabelVisible && <Title>{labelText}</Title>}
                No confirmed trips
            </Alert>
        );
    }

    if (!trips?.length) return null;

    const {
        currentTrips,
        upcomingTrips,
        pastTrips,
        waitlistedTrips,
        pendingConfirmationTrips,
    } = sortTrips(trips);

    const selectedTrip = trips.find((trip) => trip.id === selectedTripId);

    if (!inUserSidebar && !isParentTicket) {
        return (
            <>
                {isLabelVisible && (
                    <div style={{ fontWeight: 600 }}>{labelText}</div>
                )}
                <div
                    style={{
                        border: "1px solid",
                        borderRadius: "4px",
                        padding: "8px 12px",
                        borderColor: "#d8dcde",
                        marginTop: "4px",
                    }}
                >
                    <div style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                        {selectedTrip?.departure?.tourCode}:{" "}
                        {selectedTrip?.departure?.name}
                    </div>
                    <SubText>
                        {selectedTrip?.departure?.departureDate
                            ? dayjs
                                  .utc(selectedTrip.departure.departureDate)
                                  .format("MMM D, YYYY")
                            : null}{" "}
                        ({getDPDString(selectedTrip?.departure?.departureDate)})
                    </SubText>
                </div>
            </>
        );
    }

    return (
        <Dropdown
            selectedItem={selectedTrip}
            onSelect={(item: ZendeskAppTrip | undefined) =>
                setSelectedTripId(item?.id)
            }
            downshiftProps={{
                itemToString: (item: ZendeskAppTrip | undefined) =>
                    item?.id ?? "",
            }}
        >
            <Field>
                <Label hidden={!isLabelVisible}>{labelText}</Label>
                <Select isCompact>
                    <div>
                        {selectedTrip?.departure?.tourCode}:{" "}
                        {selectedTrip?.departure?.name}
                    </div>
                    <SubText>
                        {selectedTrip?.departure?.departureDate
                            ? dayjs
                                  .utc(selectedTrip?.departure?.departureDate)
                                  .format("MMM D, YYYY")
                            : null}{" "}
                        ({getDPDString(selectedTrip?.departure?.departureDate)})
                    </SubText>
                </Select>
            </Field>
            <Menu maxHeight={maxHeight}>
                {currentTrips.length ? (
                    <>
                        <HeaderItem>Current Trip</HeaderItem>
                        {currentTrips.map((trip) => (
                            <Item key={trip.id} value={trip}>
                                {trip.departure?.name}
                                <ItemMeta>
                                    {dayjs
                                        .utc(trip.departure?.departureDate)
                                        .format("MMM D, YYYY")}{" "}
                                    (
                                    {getDPDString(
                                        trip.departure?.departureDate,
                                    )}
                                    )
                                </ItemMeta>
                            </Item>
                        ))}
                    </>
                ) : null}
                {upcomingTrips.length ? (
                    <>
                        {currentTrips.length ? <Separator /> : null}
                        <HeaderItem>Upcoming Trips</HeaderItem>

                        {upcomingTrips.map((trip) => (
                            <Item key={trip.id} value={trip}>
                                {trip.departure?.name}
                                <ItemMeta>
                                    {dayjs
                                        .utc(trip.departure?.departureDate)
                                        .format("MMM D, YYYY")}{" "}
                                    (
                                    {getDPDString(
                                        trip.departure?.departureDate,
                                    )}
                                    )
                                </ItemMeta>
                            </Item>
                        ))}
                    </>
                ) : null}
                {pastTrips.length ? (
                    <>
                        {currentTrips.length || upcomingTrips.length ? (
                            <Separator />
                        ) : null}
                        <HeaderItem>Past Trips</HeaderItem>

                        {pastTrips.map((trip) => (
                            <Item key={trip.id} value={trip}>
                                {trip.departure?.name}
                                <ItemMeta>
                                    {dayjs
                                        .utc(trip.departure?.departureDate)
                                        .format("MMM D, YYYY")}{" "}
                                </ItemMeta>
                            </Item>
                        ))}
                    </>
                ) : null}

                {pendingConfirmationTrips.length ? (
                    <>
                        <HeaderItem>Pending Confirmation Trips</HeaderItem>
                        {pendingConfirmationTrips.map((trip) => (
                            <Item key={trip.id} value={trip}>
                                {trip.departure?.name}
                                <ItemMeta>
                                    {dayjs
                                        .utc(trip.departure?.departureDate)
                                        .format("MMM D, YYYY")}{" "}
                                    (
                                    {getDPDString(
                                        trip.departure?.departureDate,
                                    )}
                                    )
                                </ItemMeta>
                            </Item>
                        ))}
                    </>
                ) : null}

                {waitlistedTrips.length ? (
                    <>
                        <HeaderItem>Waitlisted Trips</HeaderItem>
                        {waitlistedTrips.map((trip) => (
                            <Item key={trip.id} value={trip}>
                                {trip?.departure?.name}
                                <ItemMeta>
                                    {dayjs
                                        .utc(trip.departure?.departureDate)
                                        .format("MMM D, YYYY")}{" "}
                                    (
                                    {getDPDString(
                                        trip.departure?.departureDate,
                                    )}
                                    )
                                </ItemMeta>
                            </Item>
                        ))}
                    </>
                ) : null}
            </Menu>
        </Dropdown>
    );
};
